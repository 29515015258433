<div class="business-operations-container">
  <ng-container>
    <lib-loader [loaderText]="loadingMessage" *ngIf="isLoading;else isLoaded"></lib-loader>
  </ng-container>
  <ng-template #isLoaded>
    <ng-container *ngIf="!showModal; else BusinessOpeModal">
      <div class="business-operations-main">
        <div class="business-operations-title" (click)="handleModal()">Actions mises en place</div>
        <div>
          <div class="business-operations-values-container">
            <div class="business-operations-wrapper">
              <div class="business-operations-values">
                <div class="business-operations-number">{{ businessOperationsWS?.promotionalOperations }}</div>
                <div class="underline" (click)="handleModal($event, businessOperationsWS.promotionalOperations)">Opérations promotionnelles</div>
              </div>
              <div class="business-operations-values">
                <div class="business-operations-number">{{ businessOperationsWS?.terrainOperations }}</div>
                <div class="underline" (click)="handleModal($event, businessOperationsWS.terrainOperations)">Opérations terrain</div>
              </div>
            </div>
            <div class="business-operations-wrapper">
              <div class="business-operations-values">
                <div class="business-operations-number">{{ businessOperationsWS?.titleNews }}</div>
                <div class="underline" (click)="handleModal($event, businessOperationsWS.titleNews)">Titres infos</div>
              </div>
              <div class="business-operations-values">
                <div class="business-operations-number">{{ businessOperationsWS?.marketingStudies }}</div>
                <div class="underline" (click)="handleModal($event, businessOperationsWS.marketingStudies)">Réglages</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #BusinessOpeModal>
      <lib-business-operations-modal [includeHS]="includeHS" [multipleFilters]="multipleFilters" [apiUrl]="apiUrl" [opeNbr]="opeNbr" [specifiedOperation]="specifiedOperation"
                                     (closeModal)="handleModal()"></lib-business-operations-modal>
    </ng-template>
  </ng-template>
</div>
