/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../dist/components-library/components-library.ngfactory";
import * as i3 from "components-library";
import * as i4 from "./app.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./services/common/logger.service";
import * as i7 from "./app.config";
import * as i8 from "./services/common/gtm.service";
import * as i9 from "./services/data/user.service";
import * as i10 from "./services/common/toast.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "lib-toaster", [], null, null, null, i2.View_ɵbl_0, i2.RenderType_ɵbl)), i0.ɵdid(3, 114688, null, 0, i3.ɵbl, [], { toasts: [0, "toasts"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.toastService.toasts; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i4.AppComponent, [i1.Router, i5.TranslateService, i6.LoggerService, i7.AppConfig, i8.GTMService, i9.UserService, i10.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
