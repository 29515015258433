<lib-compare-parutions *ngIf="compareParution?.showCompare" [currentParution]="compareParution?.refParution"
                       [currentPublicationId]="compareParution?.refParution?.publicationId"
                       [comparedParution]="compareParution?.compareParution" additonalModifyButton="true"
                       [comparableParutions]="compareParution?.comparableParutions"
                       (openModal)="handleModal($event)"></lib-compare-parutions>
<lib-compare-parutions-list *ngIf="compareListModal" [comparablePublications]="compareParution?.comparableParutions"
                            [currentParution]="compareParution?.refParution"
                            [currentTitle]="compareParution?.refParution?.name" [newRefParution]="modifyRefParution"
                            (toCompareParution)="changeComparedParution($event)"
                            (closeModal)="handleModal(true)"></lib-compare-parutions-list>
<div class="container">
  <ng-container>
    <lib-loader *ngIf="isLoading; else noLoader"></lib-loader>
  </ng-container>

  <ng-template #noLoader>
    <lib-publications-modal *ngIf="modalOpened" [parutionsNbr]="parutionsNbr" [params]="params" [paramType]="paramType" [apiUrl]="apiUrl" [useMirror]="true"
                            [parutionsType]="parutionsType" (closeModal)="closeModal()"
                            (filterChange)="onFilterChange($event)"></lib-publications-modal>
    <div class="parutions-container">
      <div class="subtitle-container">
        <h6 class="subtitle">Parutions prises en compte dans les calculs</h6>
      </div>
      <div class="stat-container"
           *ngIf="showCurrentMarket()"
           (click)="openModal(currentMarket)">
        <div class="top-stat">
            <span class="to-border"><span
              class="number">{{ currentMarket === 'family' ? parutionsIncludedNbr.family : parutionsIncludedNbr.sprint | number }}</span><span>
                parutions</span></span>
        </div>
        <div class="bottom-stat">
          <span>marché {{currentMarket === 'market' ? 'SPRINT' : 'sous famille'}}</span>
        </div>
      </div>
      <div class="stat-container" (click)="openModal('finance')" value="parutionsIncluded?.finance?.length">
        <div class="top-stat">
          <span class="to-border"><span
            class="number">{{ parutionsIncludedNbr?.finance | number }}</span><span>{{parutionsIncludedNbr?.finance === 1 ? ' parution' : ' parutions'}}</span></span>
        </div>
        <div class="bottom-stat">
          <span>stat finance</span>
        </div>
      </div>
      <div class="stat-container" (click)="openModal('sales')">
        <div class="top-stat">
            <span class="to-border"><span class="number">{{ parutionsIncludedNbr?.sales | number }}</span><span>{{parutionsIncludedNbr?.sales === 1 ? ' parution' : ' parutions'}}</span></span>
        </div>
        <div class="bottom-stat">
          <span>stat de vente</span>
        </div>
      </div>
    </div>
    <div class="checkbox-container">
      <label class="checkbox-button">
        <input type="checkbox" class="checkbox-button-input" id="hs" name="hs" [checked]="includeHS"
               (change)="handleHS($event)">
        <span class="checkbox-button-control"></span>
        <span class="checkbox-button-label">Inclure les Hors-série</span>
      </label>
    </div>
  </ng-template>
</div>
