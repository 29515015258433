import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config";
var UserService = /** @class */ (function () {
    function UserService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.currentUser = null;
    }
    UserService.prototype.getCurrentUser = function () {
        var _this = this;
        // If the user is already loaded, return it.
        if (this.currentUser) {
            return of(this.currentUser);
        }
        // ... else call the WS
        return this.httpClient
            .get(this.config.apiUrl + "/user-info", {
            withCredentials: true,
        })
            .pipe(map(function (user) {
            _this.currentUser = user;
            return _this.currentUser;
        }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
