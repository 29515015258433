<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div class="annual-sales-left-wrapper">
    <div class="annual-sales-amount-label">
      <!-- TODO : traductions -->
      CA annuel au {{ todaysDate | date: 'dd/MM/yyyy' }}
      <lib-tooltip contentType="text" [content]="tooltipContent" icon="info"></lib-tooltip>
    </div>
    <div class="annual-sales-amount-amount">
      {{ annualSalesWSResponse?.amount | currency: annualSalesWSResponse?.currency:'symbol':'.0-0':'fr' }}
    </div>
  </div>
  <div class="annual-sales-right-wrapper">
    <div class="annual-sales-versus-label">Versus A-1</div>
    <div class="annual-sales-percentage-value">
      {{ annualSalesWSResponse?.mirror | versuspercent }}
    </div>
  </div>
</ng-template>
