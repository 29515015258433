<ng-container>
  <lib-loader [loaderText]="loadingMessage" *ngIf="isLoading; else isLoaded"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <section>
    <h3 class="title">Finances</h3>
    <ul class="item-list">
      <li class="item" *ngFor="let statItem of FinancialStats">
        <div class="value-n-indicator">
          <span class="value">{{ getThousandFormatedNumber(statItem.amount).value | number: '':'fr-FR' }}</span>
          <span class="indicator">{{ getThousandFormatedNumber(statItem.amount).unit }}{{ statItem.currency }}</span>
        </div>
        <div class="name-n-evaluation">
          <div class="name">{{ statItem.label }}</div>
          <div class="evaluation">
            <div *ngIf="!compareParution?.showCompare" class="evaluation-type">{{ 'Evol. année-1' }}</div>
            <div *ngIf="compareParution?.showCompare" class="evaluation-type">{{ 'Evol. parution comparée' }}</div>
            <div class="evaluation-value">{{ statItem.mirror | custompercent }}</div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</ng-template>
