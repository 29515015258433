<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage" displayMode="both" loaderSize="big"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div #scrollAnchor class="parutions-list">
    <div class="parutions-list-header">
      <div class="parutions-list-header-top">
        <div class="parutions-list-header-top-title">
          <span class="parutions-list-header-top-nbpublications">{{ parutionsList.publicationNumber }}</span>
          parutions en vente <span class="display-on-desktop">en ce moment</span>
        </div>
        <div class="parutions-list-header-top-update">
          Données de taux de vente et de rupture basées sur un panel significatif de {{ parutionsList.nbSellers | number: '':'fr-FR' }} diffuseurs
        </div>
      </div>
      <div class="parutions-list-header-filters">
        <div class="parutions-list-header-filters-global">
          <lib-select
            clearable="true"
            [options]="parutionsList?.filters?.filterByItems"
            [model]="savedFilter"
            placeholder="Filter par"
            ngClassName="filter-select"
            (change)="onFilter($event)"
          ></lib-select>
        </div>
        <div class="parutions-list-header-filters-date">
          <lib-select
            clearable="true"
            [options]="parutionsList?.filters?.orderByItems"
            placeholder="Trier par"
            ngClassName="filter-select"
            (change)="onOrderByItems($event)"
          ></lib-select>
        </div>
      </div>
    </div>
    <div class="parutions-list-display">
      <ng-container *ngFor="let publication of publicationsPages[currentPage - 1]">
        <!-- On a des anomalies OU on est au jour ISIS J => MEV -->
        <lib-parution
          *ngIf="
            setPublicationToSale(publication) === 0 ||
            (setPublicationToSale(publication) > 0 && publication.nbWarehousesInAlert > 0)
          "
          class="parutions-list-display-item"
          [publication]="publication"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [leftStatus]="{
            value: publication.upForSaleRate,
            code: 'upForSaleRate',
            label: 'MEV',
            isPercent: true,
            withCircle: true,
            circleColor: 'blue',
            alertColor: 'white'
          }"
          [rightStatus]="{
            value: publication.nbWarehousesInAlert,
            label: 'Dépôts en anomalie',
            alertColor: 'orange',
            icon: 'info',
            tooltipContent: alertMessageContent[publication.publicationId],
            tooltipContentType: 'list',
            tooltipPosition: 'top'
          }"
        >
        </lib-parution>
        <!-- Pas d'anomalies ET on est au jour ISIS J + x => Tx de vente -->
        <lib-parution
          *ngIf="setPublicationToSale(publication) > 0 && !publication.nbWarehousesInAlert"
          class="parutions-list-display-item"
          [publication]="publication"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [leftStatus]="{
            value: publication.soldRate,
            code: 'soldRate',
            label: 'Tx vente',
            isPercent: true,
            withCircle: true,
            circleColor: 'yellow',
            alertColor: 'white'
          }"
          [rightStatus]="{
            value: publication.ruptureRate,
            label: 'Tx rupture',
            isPercent: true,
            icon: getIconRupture(publication),
            alertColor: getColorRupture(publication)
          }"
          [apiUrlForCompare]="apiUrl"
        >
        </lib-parution>
      </ng-container>
    </div>
    <lib-pagination
      [numberOfItems]="parutionsList.publications.length"
      [itemsPerPage]="nbItemPerPage"
      [currentPage]="currentPage"
      (pageChange)="paginationPageChange($event)"
      *ngIf="publicationsPages && publicationsPages.length > 1"
    ></lib-pagination>
  </div>
</ng-template>
