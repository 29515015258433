import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TemplateMasterComponent } from './pages/template-master/template-master.component';
import { AuthenticationGuard } from './services/common/authentication.guard';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';

const routes: Routes = [
  { path: '', redirectTo: '/page/tdb', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'page/:template',
    component: TemplateMasterComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
