import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { UserService } from '../data/user.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  get token(): string {
    return localStorage.getItem('token');
  }

  set token(token: string) {
    if (typeof token !== 'undefined' && token !== null) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }

  get tokenExpirationDate(): string {
    return localStorage.getItem('tokenExpirationDate');
  }

  set tokenExpirationDate(tokenExpirationDate: string) {
    if (typeof tokenExpirationDate !== 'undefined' && tokenExpirationDate !== null) {
      localStorage.setItem('tokenExpirationDate', tokenExpirationDate);
    } else {
      localStorage.removeItem('tokenExpirationDate');
    }
  }

  constructor(
    private httpClient: HttpClient,
    private log: LoggerService,
    private config: AppConfig,
    private userService: UserService
  ) {}

  async login(userName: string, password: string) {
    const authData = await this.httpClient
      .post<any>(
        `${this.config.apiUrl}/login`,
        new HttpParams().append('username', userName).append('password', password)
      )
      .toPromise();
    if (authData) {
      this.log.debug('AUTH', authData);
      this.token = authData.token;
      this.tokenExpirationDate = authData.expire;
      this.userService.currentUser = authData.account;
      this.userService.currentUser.groups = authData.groups;
    }

    return authData;
  }

  logout() {
    this.token = null;
    this.tokenExpirationDate = null;
    this.userService.currentUser = null;
  }
}
