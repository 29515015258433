<div class="bubble-container" (click)="handleClicked($event)">
  <ng-container [ngSwitch]="contentType">
    <span class="simple-content" *ngSwitchCase="contentTypeEnum.TEXT">
      {{ content }}
    </span>
    <div class="list-container" *ngSwitchCase="contentTypeEnum.LIST">
      <ul
        class="list-content"
        *ngFor="let itemGroup of getFormatedContentValues()"
      >
        <li class="item" *ngFor="let item of itemGroup">
          <span class="label">{{ item.label }}</span>
          <span class="separator"></span>
          <span class="value">{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </ng-container>
</div>
