<div class="incidents-list">
  <!-- TODO: Wording -->
  <div class="incidents-list-title">Informations de distribution</div>
  <div class="incidents-list-warehouses">
    {{ warehousesList?.length > 0 ? warehousesList?.length : 'Aucun' }} dépôt{{
      warehousesList?.length > 1 ? 's' : ''
    }}
    en anomalie{{ warehousesList?.length > 1 ? ' :' : '.' }}
    {{ warehousesList | arrayJoin: ', ' }}
  </div>
  <div class="incidents-list-message">
    <span class="only-mobile-details" (click)="onSeeMore()" *ngIf="globalMessage?.length > 0">
      {{ seeMoreString }}
      <span class="see-more-details-arrow" [ngClass]="{ up: seeMore }"></span>
    </span>
    <div class="incidents-list-message-details" [innerHTML]="globalMessage | sanitizeHtml" *ngIf="seeMore"></div>
    <div
      class="incidents-list-message-desktop"
      *ngIf="globalMessage?.length > 0 && !seeMore"
      [innerHTML]="globalMessage | truncate: 400:'...':true | sanitizeHtml"
    ></div>
    <span class="see-more" (click)="onSeeMore()" *ngIf="globalMessage?.length > 400">{{ seeMoreString }}</span>
  </div>
</div>
