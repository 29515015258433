import * as i0 from "@angular/core";
var GTMService = /** @class */ (function () {
    function GTMService() {
    }
    GTMService.prototype.createDataLayerObject = function (userName) {
        if (userName) {
            if (userName.includes('@')) {
                userName = userName.split('@')[0];
            }
            return {
                'status': 'Connecté',
                'userId': userName.substr(0, 5),
                'editorCode': userName,
            };
        }
        return {
            'status': 'Non connecté',
            'userId': '',
            'editorCode': '',
        };
    };
    GTMService.ngInjectableDef = i0.defineInjectable({ factory: function GTMService_Factory() { return new GTMService(); }, token: GTMService, providedIn: "root" });
    return GTMService;
}());
export { GTMService };
