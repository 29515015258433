<div class="container" *ngIf="salesSegmentLine">
  <div class="container-line" [ngClass]="{ main: isMain }">
    <!-- TITLE -->
    <div class="container-line-title" *ngIf="isMain">
      <div class="desktop-title">{{ salesSegmentLine?.salesPointType }}</div>
      <div class="mobile-title">{{ salesSegmentLine?.salesPointType }}</div>
    </div>
    <div class="container-line-title" *ngIf="!isMain">{{ salesSegmentLine?.salesPointType }}</div>
    <div class="container-line-content">
      <!-- SALES -->
      <div class="container-line-global" [ngClass]="{'text-blur': salesSegmentLine?.values?.displayCode['value'] > 1}">
        <lib-button-gold
          [column]="true"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [subscription]="salesSegmentLine?.values?.displayCode['value']"
          [text]="'Ventes'"
          [center]="true"
          type="button"
          *ngIf="(line === 2 || line === 0) && salesSegmentLine?.values?.displayCode['value'] > 1"
        ></lib-button-gold>
        <div class="container-line-global-label" *ngIf="isMain">Ventes</div>
        <div class="container-line-global-data">
          <lib-percentage-circle
            *ngIf="isMain && salesSegmentLine?.values?.displayCode['value'] === 1"
            [percentage]="salesSegmentLine?.values?.value * 100"
            [color]="circleColor"
            [size]="55"
            class="no-mobile-display main-circle"
          ></lib-percentage-circle>
          <lib-arrow-indicator
            *ngIf="isMain"
            [content]="salesSegmentLine?.values?.value"
            [orientation]="orientation"
            [blur]="salesSegmentLine?.values?.displayCode['value'] > 1"
            size="28"
            class="main-height no-mobile-display"
          ></lib-arrow-indicator>
          <lib-arrow-indicator
            *ngIf="isMain"
            [content]="salesSegmentLine?.values?.value"
            [orientation]="orientation"
            size="24"
            class=" main-height no-desktop-display "
          ></lib-arrow-indicator>
          <lib-arrow-indicator
            *ngIf="!isMain"
            [content]="salesSegmentLine?.values?.value"
            [blur]="salesSegmentLine?.values?.displayCode['value'] > 1"
            [orientation]="orientation"
          ></lib-arrow-indicator>
        </div>
      </div>

      <!-- PREVIOUS YEAR -->
      <div class="container-line-previous" [ngClass]="{'text-blur': salesSegmentLine?.values?.displayCode['mirror'] > 1}">
        <lib-button-gold
          [column]="true"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [subscription]="salesSegmentLine.values.displayCode['mirror']"
          [text]="'Année-1'"
          [center]="true"
          type="button"
          *ngIf="(line === 2 || line === 0) && salesSegmentLine?.values?.displayCode['mirror'] > 1"
        ></lib-button-gold>
        <div class="container-line-previous-label" *ngIf="!compareMode">Année-1</div>
        <div class="container-line-previous-label" *ngIf="compareMode">Parution comp.</div>
        <lib-percentage-circle
          *ngIf="isMain && salesSegmentLine?.values?.displayCode['value'] === 1"
          [percentage]="salesSegmentLine?.values?.mirror * 100"
          color="black"
          [size]="55"
          class="no-mobile-display main-circle"
        ></lib-percentage-circle>
        <div class="container-line-previous-data main-height">
          {{ salesSegmentLine?.values?.mirror | percent: '.0-1' }}
        </div>
      </div>

      <!-- MARKET COMPARE -->
      <div class="container-line-market" [ngClass]="{'text-blur': (salesSegmentLine?.values?.displayCode)[filterMarket] > 1}">
        <lib-button-gold
          [column]="true"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [subscription]="(salesSegmentLine?.values?.displayCode)[filterMarket]"
          [text]="marketLabel"
          [center]="true"
          type="button"
          *ngIf="(line === 2 || line === 0) && (salesSegmentLine?.values?.displayCode)[filterMarket] > 1"
        ></lib-button-gold>
        <div class="container-line-market-label">{{ marketLabel }}</div>
        <lib-percentage-circle
          *ngIf="isMain && salesSegmentLine?.values?.displayCode['value'] === 1"
          [percentage]="(salesSegmentLine?.values)[filterMarket] * 100"
          [color]="marketCircle"
          [size]="55"
          class="no-mobile-display main-circle"
        ></lib-percentage-circle>
        <div class="container-line-market-data main-height">
          {{ (salesSegmentLine?.values)[filterMarket] | percent: '.0-1' }}
        </div>
      </div>
    </div>
  </div>
</div>
