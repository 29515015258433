<div class="alerts-history-container" *ngIf="modalToggled">
  <div class="alerts-history-header">
    <div class="alerts-history-title">Historique d'alerte</div>
    <div class="alerts-history-close" (click)="onToggleModal($event)">X</div>
  </div>
  <div class="alerts-history-body">
    <ng-container *ngIf="preparationAlerts?.length > 0 || saleAlerts?.length > 0; else noAlerts">
      <div class="alerts-history-values-preparation">
        <div>EN PRÉPARATION</div>
        <ul class="alerts-history-values">
          <li *ngFor="let alert of preparationAlerts">
            {{ alert.date | date: 'dd.MM' }}
            <span class="alert-history-name-alert">{{ alert.title }}</span>
            <br/>
            <div class="alert-history-message-alert">{{ alert.message }}</div>
          </li>
        </ul>
      </div>
      <div class="alerts-history-values-vente">
        <div>EN VENTE</div>
        <ul class="alerts-history-values">
          <li *ngFor="let alert of saleAlerts">
            {{ alert.date | date: 'dd.MM' }}
            <span class="alert-history-name-alert">{{ alert.title }}</span>
            <br/>
            <div class="alert-history-message-alert">{{ alert.message }}</div>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #noAlerts>
    <div class="no-alerts">
      Pas d'alertes
    </div>
    </ng-template>
  </div>
</div>
