import * as log from 'loglevel';
import * as i0 from "@angular/core";
var LoggerService = /** @class */ (function () {
    function LoggerService() {
        // Reuse loglevel functions
        this.setLevel = log.setLevel;
        this.trace = log.trace;
        this.debug = log.debug;
        this.info = log.info;
        this.warn = log.warn;
    }
    LoggerService.prototype.error = function (error, opts) {
        log.error(error, opts);
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
