/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-master.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../dist/components-library/components-library.ngfactory";
import * as i3 from "components-library";
import * as i4 from "@angular/common/http";
import * as i5 from "./template/template.component.ngfactory";
import * as i6 from "./template/template.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/common/logger.service";
import * as i9 from "../../app.config";
import * as i10 from "../../services/data/event-data.service";
import * as i11 from "../../services/common/input.service";
import * as i12 from "./template-master.component";
import * as i13 from "../../services/data/user.service";
import * as i14 from "../../services/common/authentication.service";
import * as i15 from "../../services/common/gtm.service";
var styles_TemplateMasterComponent = [i0.styles];
var RenderType_TemplateMasterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateMasterComponent, data: {} });
export { RenderType_TemplateMasterComponent as RenderType_TemplateMasterComponent };
export function View_TemplateMasterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { compHost: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "header", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "lib-header", [], null, [[null, "brandChanged"], [null, "editorChanged"], [null, "logoutClicked"], [null, "changePage"], [null, "brandEditorChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("brandChanged" === en)) {
        var pd_0 = (_co.onBrandChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("editorChanged" === en)) {
        var pd_1 = (_co.onEditorChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("logoutClicked" === en)) {
        var pd_2 = (_co.onLogoutClicked() !== false);
        ad = (pd_2 && ad);
    } if (("changePage" === en)) {
        var pd_3 = (_co.onPageChanged($event) !== false);
        ad = (pd_3 && ad);
    } if (("brandEditorChanged" === en)) {
        var pd_4 = (_co.onBrandEditorChanged($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_ɵcc_0, i2.RenderType_ɵcc)), i1.ɵdid(4, 114688, null, 0, i3.ɵcc, [i4.HttpClient], { apiUrl: [0, "apiUrl"], setCurrentUser: [1, "setCurrentUser"], currentPage: [2, "currentPage"], selectEnabled: [3, "selectEnabled"], brand: [4, "brand"] }, { brandChanged: "brandChanged", brandEditorChanged: "brandEditorChanged", editorChanged: "editorChanged", logoutClicked: "logoutClicked", changePage: "changePage" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "main", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-template", [], null, [[null, "canSwitchBrand"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("canSwitchBrand" === en)) {
        var pd_0 = (_co.displayHeaderSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TemplateComponent_0, i5.RenderType_TemplateComponent)), i1.ɵdid(7, 49152, null, 0, i6.TemplateComponent, [i4.HttpClient, i7.ActivatedRoute, i7.Router, i8.LoggerService, i9.AppConfig, i10.EventDataService, i11.InputService], { template: [0, "template"] }, { canSwitchBrand: "canSwitchBrand" }), (_l()(), i1.ɵeld(8, 0, null, null, 3, "footer", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "lib-footer", [], null, null, null, i2.View_ɵbz_0, i2.RenderType_ɵbz)), i1.ɵdid(11, 114688, null, 0, i3.ɵbz, [i4.HttpClient], { apiUrl: [0, "apiUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.config.apiUrl, "/header"); var currVal_1 = _co.currentUser; var currVal_2 = _co.template; var currVal_3 = _co.canSwitchBrand; var currVal_4 = _co.eventDataService.currentBrandEditor.brand.label; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.template; _ck(_v, 7, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.config.apiUrl, "/footer"); _ck(_v, 11, 0, currVal_6); }, null); }
export function View_TemplateMasterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-master", [], null, null, null, View_TemplateMasterComponent_0, RenderType_TemplateMasterComponent)), i1.ɵdid(1, 245760, null, 0, i12.TemplateMasterComponent, [i7.ActivatedRoute, i7.Router, i8.LoggerService, i13.UserService, i14.AuthenticationService, i15.GTMService, i9.AppConfig, i10.EventDataService, i11.InputService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateMasterComponentNgFactory = i1.ɵccf("app-template-master", i12.TemplateMasterComponent, View_TemplateMasterComponent_Host_0, {}, {}, []);
export { TemplateMasterComponentNgFactory as TemplateMasterComponentNgFactory };
