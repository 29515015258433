import { NgZone } from '@angular/core';
import { ErrorService } from './error.service';
import * as i0 from "@angular/core";
import * as i1 from "./error.service";
export var MessageType;
(function (MessageType) {
    MessageType["ERROR"] = "error";
    MessageType["SUCCESS"] = "success";
})(MessageType || (MessageType = {}));
var ToastService = /** @class */ (function () {
    function ToastService(zone, errorService) {
        this.zone = zone;
        this.errorService = errorService;
        this.toasts = [];
    }
    ToastService.prototype.addToast = function (message, messageType, timeoutInMs) {
        var _this = this;
        if (messageType === void 0) { messageType = MessageType.SUCCESS; }
        if (timeoutInMs === void 0) { timeoutInMs = 5000; }
        // Create the toast item.
        var toastItem = { message: message.replace('\n', '<br/>'), messageType: messageType, timeoutInMs: timeoutInMs };
        // Add the toast to the array.
        // Note: Zone is used
        this.zone.run(function () { return _this.toasts.push(toastItem); });
        setTimeout(function () {
            _this.zone.run(function () {
                var toastIndex = _this.toasts.indexOf(toastItem);
                if (toastIndex > -1) {
                    _this.toasts.splice(_this.toasts.indexOf(toastItem), 1);
                }
            });
        }, timeoutInMs);
    };
    ToastService.prototype.showError = function (error, message, timeoutInMs) {
        if (message === void 0) { message = null; }
        if (timeoutInMs === void 0) { timeoutInMs = 10000; }
        var toastMessage = message ? message + "<br/>" : '';
        toastMessage += this.errorService.getErrorMessage(error);
        this.addToast(toastMessage, MessageType.ERROR, timeoutInMs);
    };
    ToastService.prototype.clearToasts = function () {
        this.toasts.splice(0, this.toasts.length);
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.inject(i0.NgZone), i0.inject(i1.ErrorService)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
