<article class="container">
  <div class="parution-cover" (mouseenter)="mouseHovering()" (mouseleave)="mouseLeft()">
    <div class="top-status" *ngIf="leftStatus || rightStatus">
      <span class="day-alert" *ngIf="mevDelayDelta">J{{ mevDelayDelta }}</span>
    </div>
    <div class="parution-state">
      <div class="parution-info">
        <div class="number-n-title">
          {{ publicationNumberNTile }}
        </div>
        <div class="mev-dates">
          <span class="promo" *ngIf="upcomingPublication?.publication?.isPromo">{{ 'promo' }}</span>

          <ng-container *ngIf="upcomingPublication?.publication?.sellingEndDate; else noEndDate">
            <span
            >MEV du {{ upcomingPublication?.publication?.sellingStartDate | date: 'dd/MM' }} au
              {{ upcomingPublication?.publication?.sellingEndDate | date: 'dd/MM' }}</span
            >
          </ng-container>
          <ng-template #noEndDate>
            <span>MEV à partir du {{ upcomingPublication?.publication?.sellingStartDate | date: 'dd/MM' }}</span>
          </ng-template>
        </div>
      </div>
      <div class="left-status" [ngClass]="leftStatusColor">{{ leftStatus | uppercase }}</div>
    </div>
    <div class="bottom-status" *ngIf="rightStatus">
      <lib-publication-state
        class="right-status"
        *ngIf="rightStatus"
        [value]="rightStatus?.value"
        [label]="rightStatus?.label"
        [alertColor]="rightStatus?.alertColor"
        [icon]="rightStatus?.icon"
        [tooltipContentType]="rightStatus?.tooltipContentType"
        [tooltipContent]="rightStatus?.tooltipContent"
        [tooltipPosition]="rightStatus?.tooltipPosition"
        [isFromUpcoming]="true"
        [showTooltip]="showTooltip"
      >
      </lib-publication-state>
    </div>
  </div>
  <div
    *ngIf="upcomingPublication.serviceRequests?.requests !== null || upcomingPublication.storeRequests?.requests !== null"
    class="parution-infos">
    <div class="info" *ngIf="upcomingPublication.storeRequests?.requests !== null">
      <div class="sub-info">
        <span class="big-number">{{upcomingPublication.storeRequests?.requests}}</span> dem. modif. service
        diffuseurs
      </div>
      <div class="sub-info">
        <div class="flex-container">
          <lib-percentage-circle class="circle"
                                 [percentage]="(upcomingPublication.storeRequests?.rate * 100).toFixed(0)"
                                 size="35"
                                 color="yellow">
          </lib-percentage-circle>
          <span>{{(upcomingPublication.storeRequests?.rate * 100).toFixed(0)}}
            % acceptées (soit {{upcomingPublication.storeRequests?.accepted}}
            demandes)</span>
        </div>
      </div>
    </div>
    <hr
      *ngIf="upcomingPublication.serviceRequests?.requests !== null && upcomingPublication.storeRequests?.requests !== null"
      class="separator"/>
    <div class="info" *ngIf="upcomingPublication.serviceRequests?.requests !== null">
      <div class="sub-info">
        <span class="big-number">{{upcomingPublication.serviceRequests?.requests}}</span> dont dem. mise en service
      </div>
      <div class="sub-info">
        <div class="flex-container">
          <lib-percentage-circle class="circle"
                                 [percentage]="(upcomingPublication.serviceRequests?.rate * 100).toFixed(0)"
                                 size="35"
                                 color="yellow">
          </lib-percentage-circle>
          <span>{{(upcomingPublication.serviceRequests?.rate * 100).toFixed(0)}}
            % acceptées (soit {{upcomingPublication.serviceRequests?.accepted}}
            demandes)</span>
        </div>
      </div>
    </div>
  </div>
</article>
