<section *ngIf="!isLoading; else loading">
  <div class="publication-details-global-container-desktop">
    <div class="publication-details-cover desktop">
      <img [src]="dataPublication.coverUrl" alt="Cover" />
    </div>
    <div class="publication-details-desktop">
      <div class="publication-details-top-desktop">
        <div class="publication-details-title">
          {{ dataPublication.publicationId | RemoveAfterHyphenPipe }} •
          <span class="publication-details-name"> {{ dataPublication.name }} </span>
          n°{{ dataPublication.publicationNumber }}
        </div>
        <div class="publication-details-wrapper-mobile">
          <div class="publication-details-cover">
            <img [src]="dataPublication.coverUrl" alt="Cover" />
          </div>
          <div class="publication-details-container mobile">
            <table class="publication-details-information-top">
              <tr>
                <td class="publication-details-information left">
                  Prix
                  <div class="publication-details-information-value">
                    {{ dataPublication.price | number: '.2':'fr-FR' }} €
                  </div>
                </td>
                <td class="publication-details-information ">
                  Période de Vente
                  <div class="publication-details-information-value">
                    <ng-container *ngIf="dataPublication?.sellingEndDate; else noEndDate">
                      Du {{ dataPublication.sellingStartDate | date: 'dd/MM' }} au
                      {{ dataPublication.sellingEndDate | date: 'dd/MM' }}
                    </ng-container>
                    <ng-template #noEndDate>
                      <span>MEV à partir du {{ dataPublication?.sellingStartDate | date: 'dd/MM' }}</span>
                    </ng-template>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="publication-details-information left">
                  Pack
                  <div class="publication-details-information-value">{{ dataPublication.pack }}</div>
                </td>
                <td class="publication-details-information ">
                  Promo
                  <div class="publication-details-information-value">{{ dataPublication.isPromo ? 'Oui' : 'Non' }}</div>
                </td>
              </tr>
              <tr
                *ngIf="!displayDetails; else details"
                class="publication-details-information-link"
                (click)="displayMore()"
              >
                <td colspan="2">Plus de détails</td>
              </tr>
              <ng-template #details class="publication-kldetails-information-details">
                <tr>
                  <td class="publication-details-information left">
                    Fournis
                    <div class="publication-details-information-value">
                      {{ dataPublication.delivered | number: '':'fr-FR' }}
                    </div>
                  </td>
                  <td class="publication-details-information ">
                    Réassort
                    <div class="publication-details-information-value">
                      {{ dataPublication.restocking ? 'Oui' : 'Non' }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="publication-details-information left">
                    Récup. Invendus
                    <div class="publication-details-information-value">{{ dataPublication.unsoldsStatus ? 'Oui' : 'Non' }}</div>
                  </td>
                  <td class="publication-details-information ">
                    Plus produit
                    <div class="publication-details-information-value">
                      {{ dataPublication.additionalProduct ? 'Oui' : 'Non' }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="publication-details-information left">
                    Sous-famille observatoire
                    <div class="publication-details-information-value">{{ dataPublication.familyLabel }}</div>
                  </td>
                  <td class="publication-details-information ">
                    Positionnement linéaire
                    <div class="publication-details-information-value">{{ dataPublication.shelfDisplayPosition }}</div>
                  </td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
        <a
          class="publication-details-contact"
          [href]="'mailto:' + dataPublication.accountManagerEmail + '?subject=[Pressmine]'"
        >
          <lib-icon class="icon" icon="mail-outline" iconWidth="24px" iconHeight="24px"></lib-icon>
          Contacter mon chargé de compte
        </a>
      </div>
      <div class="publication-details-wrapper-desktop">
        <div *ngIf="canScrollLeft" class="chevron left">
          <lib-icon icon="chevron-right" (click)="scrollDetails('left')" iconHeight="24px" iconWidth="24px"></lib-icon>
        </div>
        <div #detailsList [ngClass]="{ 'publication-details-container': true, desktop: true }">
          <div class="publication-details-information left first">
            Prix
            <div class="publication-details-information-value">
              {{ dataPublication.price | number: '.2':'fr-FR' }} €
            </div>
          </div>
          <div class="publication-details-information left">
            Période de Vente
            <div class="publication-details-information-value">
              <ng-container *ngIf="dataPublication?.sellingEndDate; else noEndDate">
                Du {{ dataPublication.sellingStartDate | date: 'dd/MM' }} au
                {{ dataPublication.sellingEndDate | date: 'dd/MM' }}
              </ng-container>
              <ng-template #noEndDate>
                <span>MEV à partir du {{ dataPublication?.sellingStartDate | date: 'dd/MM' }}</span>
              </ng-template>
            </div>
          </div>
          <div class="publication-details-information left">
            Pack
            <div class="publication-details-information-value">{{ dataPublication.pack }}</div>
          </div>
          <div class="publication-details-information left">
            Promo
            <div class="publication-details-information-value">{{ dataPublication.isPromo ? 'Oui' : 'Non' }}</div>
          </div>
          <div class="publication-details-information left">
            Fournis
            <div class="publication-details-information-value">
              {{ dataPublication.delivered | number: '':'fr-FR' }}
            </div>
          </div>
          <div class="publication-details-information left">
            Réassort
            <div class="publication-details-information-value">{{ dataPublication.restocking ? 'Oui' : 'Non' }}</div>
          </div>
          <div class="publication-details-information left">
            Récup. Invendus
            <div class="publication-details-information-value">
              {{ dataPublication.unsoldsStatus ? 'Oui' : 'Non' }}
            </div>
          </div>
          <div class="publication-details-information left">
            Plus produit
            <div class="publication-details-information-value">
              {{ dataPublication.additionalProduct ? 'Oui' : 'Non' }}
            </div>
          </div>
          <div class="publication-details-information left">
            Sous-famille observatoire
            <div class="publication-details-information-value">{{ dataPublication.familyLabel | titlecase }}</div>
          </div>
          <div class="publication-details-information left last ">
            Positionnement linéaire
            <div class="publication-details-information-value">
              {{ dataPublication.shelfDisplayPosition | titlecase }}
            </div>
          </div>
        </div>

        <div *ngIf="canScrollRight" class="chevron">
          <lib-icon icon="chevron-right" (click)="scrollDetails('right')" iconHeight="24px" iconWidth="24px"></lib-icon>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  {{ loadingMessage }}
</ng-template>
