<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <lib-compare-parutions [currentPublicationId]="publicationId" [currentParution]="currentParution"
    [comparedParution]="comparedParution" [comparableParutions]="comparableParutions"
    (changeComparedParution)="onParutionToCompareChanged($event)" (openModal)="handleModal()">
  </lib-compare-parutions>
  <lib-compare-parutions-list *ngIf="modalOpened" [comparablePublications]="comparableParutions" [currentParution]="currentParution" (toCompareParution)="updateComparedParution($event)" (closeModal)="handleModal()"></lib-compare-parutions-list>
  <div class="realtime-stats-wrapper">
    <div class="map-panel">
      <div class="switch">
        <lib-nav-switch [tabs]="tabs" background="slategray" (tabSelected)="onTabChanged($event)"></lib-nav-switch>
      </div>
      <div class="map">
        <lib-map mode="map" [labelMap]="true" [mapUrl]="mapUrl" [goldUrl]="goldUrl" [accountUrl]="accountUrl"
          [isInteractive]="true" [isEvol]="isEvol" [market]="valueMarket" [selectedWarehouse]="selectedWarehouse"
          [warehouseStats]="warehouseStats" [valueType]="valueType"
          [currentPublicationsQuartile]="warehouseCurrentPublicationValuesQuartiles"
          (warehouseChange)="onWarehouseChanged($event)"></lib-map>
      </div>
      <div class="map-additional-panel">
        <div class="map-legend-normal" *ngIf="!isEvol">
          <div class="map-legend-title">{{ legendTitle }}</div>
          <div class="map-legend-bar">
            <div class="part1">0-{{ warehouseCurrentPublicationValuesQuartiles.first | percent: '.0-1':'fr' }}</div>
            <div class="part2">
              {{ warehouseCurrentPublicationValuesQuartiles.first | percent: '.0-1':'fr' }}-{{
                warehouseCurrentPublicationValuesQuartiles.mediane | percent: '.0-1':'fr'
              }}
            </div>
            <div class="part3">
              {{ warehouseCurrentPublicationValuesQuartiles.mediane | percent: '.0-1':'fr' }}-{{
                warehouseCurrentPublicationValuesQuartiles.third | percent: '.0-1':'fr'
              }}
            </div>
            <div class="part4">{{ warehouseCurrentPublicationValuesQuartiles.third | percent: '.0-1':'fr' }}-100%</div>
          </div>
        </div>
        <div class="map-legend-evol" *ngIf="isEvol">
          <div class="map-legend-title">
            Carte en évolution
          </div>
          <div class="map-legend-bar">
            <div class="part1">- performant</div>
            <div class="part2"></div>
            <div class="part3">+ performant</div>
          </div>
        </div>
        <div class="map-evol-button">
          <button class="button-evol" [class.is-evol]="isEvol" (click)="onEvolutionClicked()">
            <div class="icon-evol" [ngSwitch]="isEvol">
              <lib-icon icon="close2" *ngSwitchCase="true"></lib-icon>
              <lib-icon icon="evol" *ngSwitchDefault></lib-icon>
            </div>
            {{ !isEvol ? 'Évolution' : 'Retour à mes données' }}
          </button>
        </div>
      </div>
    </div>

    <hr class="separator" />

    <div class="details-panel">
      <div class="filters">
        <lib-select [options]="selectMarketOptions" placeholder="Afficher : sous-famille" ngClassName="filter-select"
          (change)="onMarketChanged($event)"></lib-select>
      </div>
      <div class="status">
        <div [ngClass]="{'not-updated': !warehouseStats.isUpToDate}">{{ warehouseStats.updateMessage }}</div>
        <div class="not-updated" *ngIf="!warehouseStats.isUpToDate">Données non remises à jour</div>
        <div class="details">{{ warehouseStats.updateTooltip }}</div>
      </div>
      <div class="stats">
        <lib-map-warehouse-details [warehouseDetails]="warehouseStats" [publicationId]="publicationId"
          [currentWarehouse]="selectedWarehouse" [currentParution]="currentParution"
          [comparedParution]="comparedParution" [comparableParutions]="comparableParutions" [goldUrl]="goldUrl"
          [accountUrl]="accountUrl" [market]="valueMarket" [isSegmentLoading]="isSegmentLoading" [mevDelay]="mevDelay" [selectedSegmentId]="selectedSegmentId"
          (warehouseChange)="onWarehouseChanged($event)" (segmentChange)="onSegmentChanged($event)"
          (tabChange)="onWarehouseStatsTabChange($event)">
        </lib-map-warehouse-details>
      </div>
    </div>
  </div>
</ng-template>
