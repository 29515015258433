import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { User } from './user.model';
import {Groups} from './groups.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser: User = null;

  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  getCurrentUser(): Observable<User> {
    // If the user is already loaded, return it.
    if (this.currentUser) {
      return of(this.currentUser);
    }

    // ... else call the WS
    return this.httpClient
      .get<User>(`${this.config.apiUrl}/user-info`, {
        withCredentials: true,
      })
      .pipe(
        map(user => {
          this.currentUser = user;
          return this.currentUser;
        })
      );
  }
}
