/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../dist/components-library/components-library.ngfactory";
import * as i4 from "components-library";
import * as i5 from "./login.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/common/authentication.service";
import * as i8 from "../../services/data/user.service";
import * as i9 from "../../services/common/logger.service";
import * as i10 from "../../services/common/toast.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { usernameElt: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "panel-portal-editor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "portal-editor-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pressmine"])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "form", [["class", "form-login"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(6, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "lib-input", [["class", "input-username"], ["controlName", "userName"], ["inputName", "username"], ["placeholderName", "Identifiant"], ["type", "text"]], null, null, null, i3.View_ɵp_0, i3.RenderType_ɵp)), i1.ɵdid(10, 638976, [[1, 4], ["username", 4]], 0, i4.ɵp, [], { inputName: [0, "inputName"], type: [1, "type"], placeholderName: [2, "placeholderName"], controlName: [3, "controlName"], group: [4, "group"], disabled: [5, "disabled"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "lib-input", [["controlName", "password"], ["inputName", "password"], ["placeholderName", "Mot de passe"], ["type", "password"]], null, null, null, i3.View_ɵp_0, i3.RenderType_ɵp)), i1.ɵdid(12, 638976, null, 0, i4.ɵp, [], { inputName: [0, "inputName"], type: [1, "type"], placeholderName: [2, "placeholderName"], controlName: [3, "controlName"], group: [4, "group"], disabled: [5, "disabled"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "lib-button", [["class", "button-connection"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ɵn_0, i3.RenderType_ɵn)), i1.ɵdid(14, 114688, null, 0, i4.ɵn, [], { text: [0, "text"], type: [1, "type"], disabled: [2, "disabled"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 6, 0, currVal_7); var currVal_8 = "username"; var currVal_9 = "text"; var currVal_10 = "Identifiant"; var currVal_11 = "userName"; var currVal_12 = _co.form; var currVal_13 = _co.isLogging; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "password"; var currVal_15 = "password"; var currVal_16 = "Mot de passe"; var currVal_17 = "password"; var currVal_18 = _co.form; var currVal_19 = _co.isLogging; _ck(_v, 12, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_20 = (_co.isLogging ? "Connexion en cours..." : "Connexion"); var currVal_21 = "submit"; var currVal_22 = _co.isLogging; _ck(_v, 14, 0, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoginComponent, [i2.FormBuilder, i6.ActivatedRoute, i6.Router, i7.AuthenticationService, i8.UserService, i9.LoggerService, i10.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i5.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
