import { StatisticsFilter } from './statistics-filter.model';
import * as i0 from "@angular/core";
var EventDataService = /** @class */ (function () {
    function EventDataService() {
        this.includeHS = true;
        this._statisticsFilter = new StatisticsFilter();
        this.currentBrandEditor = {
            brand: { value: null, label: null },
            editor: { value: null, label: null },
        };
    }
    Object.defineProperty(EventDataService.prototype, "statisticsFilter", {
        get: function () {
            return Object.assign({}, this._statisticsFilter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventDataService.prototype, "statisticsFilterTitle", {
        set: function (title) {
            this._statisticsFilter.title = title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventDataService.prototype, "statisticsFilterDate", {
        set: function (date) {
            this._statisticsFilter.date = date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventDataService.prototype, "statisticsFilterMarket", {
        set: function (market) {
            this._statisticsFilter.market = market;
        },
        enumerable: true,
        configurable: true
    });
    EventDataService.prototype.hasCompareParution = function () {
        return this.compareParution && this.compareParution.refParution && this.compareParution.refParution.periodId;
    };
    EventDataService.ngInjectableDef = i0.defineInjectable({ factory: function EventDataService_Factory() { return new EventDataService(); }, token: EventDataService, providedIn: "root" });
    return EventDataService;
}());
export { EventDataService };
