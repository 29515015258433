<ng-container *ngIf="updateMessage">
  <div class="status mobile">
    <div [ngClass]="{'not-updated': !tabs[activeTab].isUpToDate}">{{ tabs[activeTab].updateLabel }}</div>
    <div class="not-updated" *ngIf="!tabs[activeTab].isUpToDate">Données non remises à jour</div>
    <div class="details">{{ tabs[activeTab].tooltip }}</div>
  </div>
</ng-container>
<div class="nav-tabs">
  <div class="tabs">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <a
        role="button"
        class="nav-tabs-tab"
        [ngClass]="[background, tab.isActive ? 'active' : '']"
        (click)="onSelectTab(i)"
      >
        {{ tab.label }}
      </a>
    </ng-container>
  </div>
  <ng-container *ngIf="updateMessage">
    <div class="status desktop">
      <div [ngClass]="{'not-updated': !tabs[activeTab].isUpToDate}">{{ tabs[activeTab].updateLabel }}</div>
      <div class="not-updated" *ngIf="!tabs[activeTab].isUpToDate">Données non remises à jour</div>
      <div class="details">{{ tabs[activeTab].tooltip }}</div>
    </div>
  </ng-container>
</div>
