<div class="title-bar">
  <div class="editor-brand">
    <div class="editor">
      <lib-select
        [model]="editor"
        [options]="editors"
        class="editors-select"
        [binding]="true"
        [placeholder]="null"
        [disabled]="!selectEditorEnabled"
        searchable="true"
        [clearable]="false"
        [className]="'editor-select'"
        (change)="onEditorChanged($event)"
      ></lib-select>
    </div>

    <div class="brands">
      <lib-select
        *ngIf="!isLoading"
        [model]="brand"
        [options]="brands"
        class="brands-select"
        placeholder="Toutes les marques"
        [disabled]="!selectEnabled"
        searchable="true"
        clearable="true"
        (change)="onBrandChanged($event)"
      ></lib-select>
    </div>
  </div>
  <a role="button" class="logout" href="javascript:void(0)" (click)="logout($event)"></a>

  <div class="menu">
    <lib-menu-button
      [menu]="menu"
      (logout)="logout($event)"
      (changePage)="handleChangePage($event)"
      [currentPage]="currentPage"
    ></lib-menu-button>
  </div>
</div>
<lib-menu-desktop
  class="menu-desktop"
  [menu]="menu"
  (changePage)="handleChangePage($event)"
  [currentPage]="currentPage"
></lib-menu-desktop>
