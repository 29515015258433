import {Injectable} from '@angular/core';
import {IncidentsIndicatorFilter} from './incidents-indicator-filter.model';
import {StatisticsFilter} from './statistics-filter.model';
import { CompareParution } from 'components-library/lib/models/sales-statistics-filter';

@Injectable({
  providedIn: 'root',
})
export class EventDataService {
  public currentBrandEditor: { brand: { value: string; label: string }; editor: { value: string; label: string }; };
  public incidentsIndicatorFilter: IncidentsIndicatorFilter;
  public parutionListFilter: IncidentsIndicatorFilter;
  public upComingIndicatorFilter: IncidentsIndicatorFilter;
  public compareParution : CompareParution;
  // Id of compared parution
  public comparedParution: string;
  public includeHS = true;

  // realtime data
  public realtimeSegment: string;
  public realtimeCurrentWarehouse: string;
  public realtimeCurrentWarehouseStatsTab: string;
  public realtimeCurrentMarketType: string;
  public realtimeCurrentComparisonValue: string;

  private _statisticsFilter: StatisticsFilter = new StatisticsFilter();
  get statisticsFilter(): StatisticsFilter {
    return Object.assign({}, this._statisticsFilter);
  }

  set statisticsFilterTitle(title: string) {
    this._statisticsFilter.title = title;
  }

  set statisticsFilterDate(date: string) {
    this._statisticsFilter.date = date;
  }

  set statisticsFilterMarket(market: string) {
    this._statisticsFilter.market = market;
  }

  constructor() {
    this.currentBrandEditor = {
      brand: {value: null, label: null},
      editor: {value: null, label: null},
    };
  }

  public hasCompareParution() {
    return this.compareParution && this.compareParution.refParution && this.compareParution.refParution.periodId;
  }
}
