<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div #scrollAnchor class="container-content">
    <div class="container-header">
      <div class="header-title">
        <span>
          {{ salesPointsStats?.warehouseName ? 'Dépôt ' + salesPointsStats?.warehouseName : 'France entière' }}
        </span>
        — <span class="header-subtitle">{{ salesPointsStats?.total }} points de vente caisses fiabilisées</span>
      </div>
      <div class="position-relative header-select">
        <lib-select
          class="header-select-comp"
          [options]="[{ value: 'En rupture', label: 'En Rupture' }]"
          clearable="true"
          placeholder="Filtrer par"
          [disabled]="salesPointsStats?.displayCode?.salespoints > 1"
          (change)="onFilterBy($event)"
        ></lib-select>
        <lib-button-gold
          [subscription]="salesPointsStats?.displayCode?.export"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [text]="'Export'"
          [center]="false"
          type="button"
          *ngIf="salesPointsStats?.displayCode?.export > 1"
        ></lib-button-gold>
        <div *ngIf="salesPointsStats?.displayCode?.export === 1">
          Exporter la liste
        </div>
        <div *ngIf="salesPointsStats?.displayCode?.export === 1" class="header-download"
             (click)="onCsvDownloadClicked()">
          <img src="/assets/icons/download.svg"/>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isWholeTableLoading; else content">
      <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
    </ng-container>
    <ng-template #content>
      <div class="mobile-container">
        <ng-container *ngIf="isTableContentLoading; else mobileContent">
          <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
        </ng-container>
        <ng-template #mobileContent>
          <ng-container *ngFor="let salesPoint of salesPointsStats?.salespoints; let i = index">
            <div class="container-card"
                 [ngClass]="{'text-blur': i > 4 && salesPointsStats?.displayCode?.salespoints > 1}">
              <lib-button-gold
                [subscription]="salesPointsStats?.displayCode?.salespoints"
                [goldUrl]="goldUrl"
                [accountUrl]="accountUrl"
                [text]="'Points de vente'"
                [center]="true"
                type="button"
                *ngIf="salesPointsStats?.displayCode?.salespoints > 1 && i === 6"
              ></lib-button-gold>
              <div class="card-title">{{ salesPoint?.salespoint?.id }} • {{ salesPoint?.salespoint?.name }}
                <div class="card-subtitle">
                  {{ salesPoint?.salespoint?.street }}, {{ salesPoint?.salespoint?.postalCode }}
                  {{ salesPoint?.salespoint?.city }}
                </div>
              </div>
              <div class="card-line">
                <div class="card-distribute">
                  <div class="distribute-label">Servis</div>
                  <div class="distribute-data">
                    <div class="data-value">{{ salesPoint?.deliveredItems?.currentPublication?.value }}</div>
                    <div class="data-previous">{{ salesPoint?.deliveredItems[valueComparison]?.value }}</div>
                  </div>
                </div>
                <div class="card-sold">
                  <div class="sold-label">Vendus</div>
                  <div class="sold-data">
                    <div class="data-value">{{ salesPoint?.soldItems?.currentPublication?.value }}</div>
                    <div class="data-previous">{{ salesPoint?.soldItems[valueComparison]?.value }}</div>
                  </div>
                </div>
              </div>
              <div class="card-line">
                <div class="card-unsold">
                  <div class="unsold-label">Tx de vente</div>
                  <div class="unsold-data">
                    <div class="data-value">
                      {{ salesPoint?.soldRate?.currentPublication?.value | floorPercent }}
                    </div>
                    <div class="data-previous">
                      {{ salesPoint?.soldRate[valueComparison]?.value | floorPercent }}
                    </div>
                  </div>
                </div>
                <lib-text-badge
                  *ngIf="salesPoint?.salespoint?.currentStatus"
                  [content]="salesPoint?.salespoint?.currentStatus"
                  [background]="getRuptureColor(salesPoint?.salespoint?.currentStatus)"
                  class="text-badge"
                ></lib-text-badge>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="desktop-container">
        <div class="card-header">
          <ng-container *ngFor="let item of cardHeaderLabels; let i = index">
            <div class="card-header-label" *ngIf="salesPointsStats?.displayCode?.salespoints > 1">
              {{ item.label }}
            </div>
            <div class="card-header-label" (click)="onOrderBy(i, !item.filterSelected[0])"
                 *ngIf="salesPointsStats?.displayCode?.salespoints === 1">
              {{ item.label }}
              <div class="filter-select">
                <div class="filter-select-up" [ngClass]="{ 'filter-selected': item.filterSelected[0] }"></div>
                <div class="filter-select-down" [ngClass]="{ 'filter-selected': item.filterSelected[1] }"></div>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="isTableContentLoading; else desktopContent">
          <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
        </ng-container>
        <ng-template #desktopContent>
          <ng-container *ngFor="let salesPoint of salesPointsStats?.salespoints; let i = index">
            <div class="container-card"
                 [ngClass]="{'text-blur': i > 4 && salesPointsStats?.displayCode?.salespoints > 1}">
              <lib-button-gold
                [subscription]="salesPointsStats?.displayCode?.salespoints"
                [goldUrl]="goldUrl"
                [accountUrl]="accountUrl"
                [text]="'Points de vente'"
                [center]="true"
                type="button"
                *ngIf="salesPointsStats?.displayCode?.salespoints > 1 && i === 6"
              ></lib-button-gold>
              <div class="card-title">
                {{ salesPoint?.salespoint?.id }} • {{ salesPoint?.salespoint?.name }}
                <div class="card-subtitle">
                  {{ salesPoint?.salespoint?.street }}, {{ salesPoint?.salespoint?.postalCode }}
                  {{ salesPoint?.salespoint?.city }}
                </div>
              </div>
              <div class="distribute-data">
                <div class="data-value">{{ salesPoint?.deliveredItems?.currentPublication?.value }}</div>
                <div class="data-previous">{{ salesPoint?.deliveredItems[valueComparison]?.value }}</div>
              </div>
              <div class="sold-data">
                <div class="data-value">{{ salesPoint?.soldItems?.currentPublication?.value }}</div>
                <div class="data-previous">{{ salesPoint?.soldItems[valueComparison]?.value }}</div>
              </div>
              <div class="unsold-data">
                <div class="data-value">
                  {{ salesPoint?.soldRate?.currentPublication?.value | floorPercent }}
                </div>
                <div class="data-previous">
                  {{ salesPoint?.soldRate[valueComparison]?.value | floorPercent }}
                </div>
              </div>
              <div class="marketshare-data">
                <div class="data-value">
                  {{ salesPoint[marketType ? marketType : 'family']?.currentPublication?.value | floorPercent }}
                </div>
                <div class="data-previous">
                  {{ salesPoint[marketType ? marketType : 'family'][valueComparison]?.value | floorPercent }}
                </div>
              </div>
              <lib-text-badge
                *ngIf="salesPoint?.salespoint?.currentStatus"
                [content]="salesPoint?.salespoint?.currentStatus"
                [background]="getRuptureColor(salesPoint?.salespoint?.currentStatus)"
                class="text-badge"
              ></lib-text-badge>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <lib-pagination
        class="pagination"
        [numberOfItems]="salesPointsStats?.total"
        [itemsPerPage]="nbItemPerPage"
        [currentPage]="currentPage"
        (pageChange)="paginationPageChange($event)"
        *ngIf="havePagination() && salesPointsStats?.displayCode?.salespoints === 1"
      ></lib-pagination>
    </ng-template>
  </div>
</ng-template>
