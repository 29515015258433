<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div class="lib-incidents-overview-wrapper">
    <lib-map
      class="lib-incidents-overview-map"
      mode="minimap"
      [mapUrl]="mapUrl"
      [isInteractive]="false"
      [warehousesInAlert]="alerts?.impactedWarehouses"
    ></lib-map>

    <ng-container *ngIf="!isLoading">
      <lib-incidents-list
        [globalMessage]="alerts?.globalMessage"
        [impactedWarehouses]="alerts?.impactedWarehouses"
        class="lib-incident-overview-alert"
        *ngIf="alerts"
      ></lib-incidents-list>
    </ng-container>
  </div>
</ng-template>
