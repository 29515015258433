<ng-container *ngIf="withCircle">
  <lib-percentage-circle class="circle" [percentage]="value * 100" [color]="circleColor"></lib-percentage-circle>
</ng-container>
<div class="value-n-label" [ngClass]="{ upcoming: isFromUpcoming }">
  <div *ngIf="tooltipContent; else normalLabel">
    <div class="value-n-tooltip" [ngClass]="alertColor">
      <ng-container *ngIf="showTooltip; else showWarning">
        <lib-tooltip
          class="tooltip"
          [icon]="icon"
          [contentType]="tooltipContentType"
          [content]="tooltipContent"
          [tooltipPosition]="tooltipPosition"
          *ngIf="alertIcon"
        ></lib-tooltip>
      </ng-container>
      <ng-template #showWarning>
        <img src="/assets/icons/alerts.svg" class="tooltip warning" />
      </ng-template>
      <span class="value">
        <ng-container>{{ value }}</ng-container>
      </span>
    </div>
    <div class="label">{{ label }}</div>
  </div>
  <ng-template #normalLabel>
    <div class="value-n-tooltip" [ngClass]="alertColor">
      <img *ngIf="icon" src="/assets/icons/alerts.svg" class="tooltip warning" />
      <span class="value">
        <ng-container *ngIf="isPercent; else normalValue">
          <span title="{{ value | percent: '.0-2':'fr' }}">{{ value | floorPercent }}</span>
        </ng-container>
        <ng-template #normalValue>{{ value | number: '.0-0':'fr-FR' }}</ng-template>
      </span>
    </div>
    <div class="label">{{ label }}</div>
  </ng-template>
</div>
