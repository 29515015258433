/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./maintenance.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../app.config";
import * as i5 from "@angular/router";
var styles_MaintenanceComponent = [i0.styles];
var RenderType_MaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenanceComponent, data: {} });
export { RenderType_MaintenanceComponent as RenderType_MaintenanceComponent };
export function View_MaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "maintenance-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.htmlTemplate; _ck(_v, 2, 0, currVal_0); }); }
export function View_MaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenance", [], null, null, null, View_MaintenanceComponent_0, RenderType_MaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.MaintenanceComponent, [i3.HttpClient, i4.AppConfig, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenanceComponentNgFactory = i1.ɵccf("app-maintenance", i2.MaintenanceComponent, View_MaintenanceComponent_Host_0, {}, {}, []);
export { MaintenanceComponentNgFactory as MaintenanceComponentNgFactory };
