<div class="container">
  <ng-container *ngIf="isLoading; else isLoaded">
    <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
  </ng-container>
  <ng-template #isLoaded>
    <div class="container-title">Répartition des ventes par segment</div>
    <div class="container-content">
      <div class="container-content-top">
        <lib-sales-segment-line
          [isMain]="true"
          [salesSegmentLine]="salesSegment[0]"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [filterMarket]="filterMarket"
          [marketLabel]="marketLabel"
          [compareMode]="compareParution?.showCompare"
        ></lib-sales-segment-line>
      </div>
      <div class="container-content-middle">
        <div class="container-content-middle-label" [ngClass]="{ 'no-margin': salesSegment.length === 1 }">
          <div class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 1 && salesSegment[1]?.values?.displayCode['value'] > 1}">Ventes</div>
          <div *ngIf="!compareParution?.showCompare" class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 2 && salesSegment[2]?.values?.displayCode['mirror'] > 1}">Année-1</div>
          <div *ngIf="compareParution?.showCompare" class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 2 && salesSegment[2]?.values?.displayCode['mirror'] > 1}">Parution comp.</div>
          <div class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 3 && (salesSegment[3]?.values?.displayCode)[filterMarket] > 1}">{{ marketLabel }}</div>
        </div>
        <ng-container *ngIf="salesSegment.length > 1; else noDataAvailable">
          <ng-container *ngFor="let segment of (salesSegment | slice: 1:4); let i = index;">
            <lib-sales-segment-line
              [line]="(i + 1)"
              [goldUrl]="goldUrl"
              [accountUrl]="accountUrl"
              [salesSegmentLine]="segment"
              [filterMarket]="filterMarket"
              [marketLabel]="marketLabel"
              [compareMode]="compareParution?.showCompare"
              class="container-content-line"
            >
            </lib-sales-segment-line>
          </ng-container>
        </ng-container>
        <ng-template #noDataAvailable>
          <div class="container-content-nodata">
            Pas de données disponibles
          </div>
        </ng-template>
      </div>
      <div class="container-content-low">
        <div class="container-content-low-label">
          <div class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 4 && salesSegment[4]?.values?.displayCode['value'] > 1}">Ventes</div>
          <div *ngIf="!compareParution?.showCompare" class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 5 && salesSegment[5]?.values?.displayCode['mirror'] > 1}">Année-1</div>
          <div *ngIf="compareParution?.showCompare" class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 5 && salesSegment[5]?.values?.displayCode['mirror'] > 1}">Parution comp.</div>
          <div class="container-content-desktop-label" [ngClass]="{'text-blur': salesSegment.length > 6 && (salesSegment[6]?.values?.displayCode)[filterMarket] > 1}">{{ marketLabel }}</div>
        </div>
        <ng-container *ngIf="salesSegment.length > 4; else noDataAvailable">
          <ng-container *ngFor="let segment of (salesSegment | slice: 4:7); let i = index;">
            <lib-sales-segment-line
              [line]="(i + 1)"
              [goldUrl]="goldUrl"
              [accountUrl]="accountUrl"
              [salesSegmentLine]="segment"
              [filterMarket]="filterMarket"
              [marketLabel]="marketLabel"
              [compareMode]="compareParution?.showCompare"
              class="container-content-line"
            >
            </lib-sales-segment-line>
          </ng-container>
        </ng-container>
        <ng-template #noDataAvailable>
          <div class="container-content-nodata">
            Pas de données disponibles
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
