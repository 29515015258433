<div
  class="incidents-indicator-wrapper"
  (click)="handleClicked($event)"
  [ngClass]="{ 'is-selected': isIndicatorSelected }"
>
  <ng-container *ngIf="isLoading; else isLoaded">
    <lib-loader [loaderText]="loadingMessage"></lib-loader>
  </ng-container>
  <ng-template #isLoaded>
    <div class="incidents-indicator-container">
      <div class="incidents-indicator-number">{{ incidentsIndicator.quantity }}</div>
      <div class="incidents-indicator-message">
        {{ incidentsIndicator.label }}
        <lib-tooltip
          contentType="text"
          *ngIf="incidentsIndicator.tooltip"
          [content]="incidentsIndicator.tooltip"
          icon="info"
        ></lib-tooltip>
      </div>
      <div *ngIf="isIndicatorSelected" class="incidents-indicator-desactivate">x</div>
    </div>
  </ng-template>
</div>
