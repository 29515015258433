<section *ngIf="!isLoading; else loading">
  <button class="timeline-history-button-mobile" (click)="openHistory()">
    Historique d'alertes
  </button>

  <lib-alerts-history
    [saleAlerts]="dataHistory.inSale"
    [preparationAlerts]="dataHistory.inPreparation"
    [modalToggled]="displayHistory"
    (closeHistory)="closeHistory()"
  ></lib-alerts-history>

  <div class="timeline-container-wrapper">
    <div class="timeline-container">
      <div class="timeline-navigation-mobile">
        <div>{{ displayTimeline | titlecase }}</div>
      </div>

      <div class="timeline-navigation-desktop">
        <div>{{ displayTimeline | titlecase }}</div>
        <a class="timeline-history-link-desktop" (click)="openHistory()">Historique d'alertes</a>
      </div>

      <div
        #timelineMobile
        class="timeline-labels"
        (mousedown)="mousedown($event)"
        (mousemove)="mousemove($event)"
        (mouseup)="mouseup($event)"
      >
        <div class="timeline-first"></div>
        <div
          *ngFor="let step of dataTimelineStatusAll; let i = index"
          [ngClass]="{ 'timeline-step': true, first: i === 0 }"
        >
          <lib-timeline-label
            [type]="step.type"
            [name]="step.name"
            [date]="step.date"
            [status]="step.status"
          ></lib-timeline-label>
        </div>
        <div class="timeline-last"></div>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  {{ loadingMessage }}
</ng-template>
