<div *ngIf="!isClicked; then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
  <a role="button" class="button-open-menu" (click)="handleClicked($event)"><img src="assets/icons/menu.svg"/></a>
</ng-template>
<ng-template #elseBlock>
  <a role="button" class="button-close-menu" (click)="handleClicked($event)"><img src="assets/icons/close.svg"/></a>
</ng-template>

<div *ngIf="isClicked">
  <lib-menu-mobile
    class="menu"
    [menu]="menu"
    (logout)="handleLogout($event)"
    (changePage)="handleChangePage($event)"
    [currentPage]="currentPage"
  ></lib-menu-mobile>
  <div class="arrow-up"><div class="arrow-up-background"></div></div>
</div>
