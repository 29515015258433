import {HttpClient} from '@angular/common/http';
import {Component, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AppConfig} from 'src/app/app.config';
import {LoggerService} from 'src/app/services/common/logger.service';
import {EventDataService} from 'src/app/services/data/event-data.service';
import { CompareParution } from 'components-library/lib/models/sales-statistics-filter';
import { InputService } from '../../../services/common/input.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent {
  @ViewChild(Component)
  compHost;

  private _template: string;
  public get template(): string {
    return this._template;
  }

  @Input()
  public set template(value: string) {
    this._template = value;
    if (value) {
      this.loadTemplate();
    }
  }

  @Output()
  public canSwitchBrand: EventEmitter<any> = new EventEmitter<any>();

  public templateTree;

  public data = {};

  public selectedTabs = {};

  public queryParams: Params;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private log: LoggerService,
    public config: AppConfig,
    public eventDataService: EventDataService,
    public inputService: InputService,
  ) {
  }

  async loadTemplate() {
    if (!this.template) {
      return;
    }

    // Reinit some store service data
    this.eventDataService.statisticsFilterTitle = undefined;
    this.eventDataService.statisticsFilterDate = undefined;
    this.eventDataService.statisticsFilterMarket = undefined;
    this.eventDataService.realtimeSegment = undefined;
    this.eventDataService.realtimeCurrentWarehouse = undefined;
    this.eventDataService.realtimeCurrentMarketType = undefined;
    this.eventDataService.realtimeCurrentComparisonValue = undefined;
    this.eventDataService.incidentsIndicatorFilter = undefined;
    this.eventDataService.parutionListFilter = undefined;
    this.eventDataService.upComingIndicatorFilter = undefined;

    this.log.debug('TEMPLATE', this.template);

    const queryParams = this.route.snapshot.queryParams;
    this.log.debug('QUERY PARAMS', queryParams);
    this.selectedTabs = [];
    const queryParamsKeys = Object.keys(queryParams);
    let key;
    this.queryParams = [];
    for (let i = 0; i < queryParamsKeys.length; i++) {
      key = queryParamsKeys[i];
      this.queryParams[key] = queryParams[key];
      if (key.indexOf('tab-') === 0) {
        this.selectedTabs[key.substr(4)] = queryParams[key];
      }
    }

    const layout = await this.httpClient.get<any>(`${this.config.apiUrl}/layout?page=${this.template}`).toPromise();

    this.templateTree = layout.rows;

    this.templateTree.forEach(row => {
      row.components.forEach(comp => {
        comp.apiUrl = this.config.apiUrl + comp.apiUrl;
      });
    });
    this.canSwitchBrand.emit(layout.canSwitchBrand);
    this.log.debug('TEMPLATE TREE :: ', this.templateTree);
  }

  onTabChanged(evt, componentId) {
    this.log.debug('onTabChanged', evt);
    const newTemplate = evt.value.substr(evt.value.indexOf('=') + 1);
    const qParams = {};
    qParams['tab-' + componentId] = newTemplate;
    this.router.navigate([`/page/tdb`], {
      replaceUrl: true,
      queryParams: qParams,
      queryParamsHandling: 'merge',
    });
    // this.loadTemplate();

    this.selectedTabs['comp-' + componentId] = newTemplate;
    this.queryParams['tab-' + componentId] = newTemplate;
  }

  onFilterStatsTitleChange(event: any) {
    // ORDER IS IMPORTANT !
    this.eventDataService.statisticsFilterTitle = event;
    this.inputService.updateTitle(event);
  }

  onFilterStatsDateChange(event: any) {
    // ORDER IS IMPORTANT !
    this.eventDataService.statisticsFilterDate = event;
    this.inputService.updatePeriod(event)
  }

  onFilterStatsMarketChange(event: any) {
    this.eventDataService.statisticsFilterMarket = event;
  }

  onRealTimeSegmentChange(segment: any) {
    this.eventDataService.realtimeSegment = segment;
  }

  onRealTimeCurrentWarehouseChange(warehouse: any) {
    this.eventDataService.realtimeCurrentWarehouse = warehouse;
  }

  onRealTimeCurrentMarketTypeChange(marketType: any) {
    this.eventDataService.realtimeCurrentMarketType = marketType;
  }

  onIncidentsIndicatorClicked(event: any) {
    this.eventDataService.incidentsIndicatorFilter = event;
  }

  onParutionFilterChange(event: any) {
    this.eventDataService.parutionListFilter = event;
  }

  onIncludeHS(event: any) {
    this.eventDataService.includeHS = event;
  }

  onUpComingFilterChange(event: any) {
    this.eventDataService.upComingIndicatorFilter = event;
  }

  onCompareParution(event: CompareParution) {
    // ORDER IS IMPORTANT !
    this.eventDataService.compareParution = event;
    this.inputService.updatePeriod(event.refParution && event.refParution.periodId);
  }

  onComparedParution(event: any) {
    this.eventDataService.comparedParution = event;
  }
}
