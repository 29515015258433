<div class="container" [ngClass]="{'text-blur': blur}">
  <ng-container *ngIf="expanded; else closeItem">
    <lib-badge [content]="index" [background]="getColor()"></lib-badge>
    <div class="ranking-item-wrap expanded">
      <div class="ranking-item-name expanded">{{ item.name }}</div>
      <div *ngIf="type === 'resellers'" class="ranking-item-location">{{ item.display }}</div>
      <div class="ranking-item-wrap-expanded">
        <div *ngIf="type === 'publications'" class="ranking-item-image">
          <img [src]="item.display" alt="Cover" />
        </div>
        <div>
          <div class="ranking-item-data-value value-expanded">{{ item.values.value | number: '':'fr-FR' }}</div>
          <div class="ranking-item-number-sold-expanded">Exemplaires Vendus</div>
          <div>
            <span class="ranking-item-data-value mirror-expanded">{{ item.values.mirror | versuspercent }}</span>
            <span *ngIf="!compareMode" class="ranking-item-data-label mirror-expanded"> VS année-1</span>
            <span *ngIf="compareMode" class="ranking-item-data-label mirror-expanded"> VS parution comp.</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #closeItem>
    <div class="ranking-item-wrap-close">
      <lib-badge [content]="index" [background]="getColor()" [blur]="blur"></lib-badge>
      <div class="ranking-item-wrap">
        <div class="ranking-item-name">{{ item.name }}</div>
        <div *ngIf="type === 'resellers'" class="ranking-item-location">{{ item.display }}</div>
        <span class="ranking-item-data-label">vte </span>
        <span class="ranking-item-data-value closed">{{ item.values.value | number: '':'fr-FR' }} ex</span>
        <span class="ranking-item-data-value">{{ item.values.mirror | versuspercent }}</span>
        <span *ngIf="!compareMode" class="ranking-item-data-label"> VS année-1</span>
        <span *ngIf="compareMode" class="ranking-item-data-label"> VS parution comp.</span>
      </div>
    </div>
  </ng-template>
</div>
