<div *ngFor="let row of templateTree" class="row">
  <div *ngFor="let component of row.components" class="comp {{ component.size | sizeTransform }}">
    <hr *ngIf="component.type === 'separator'" class="separator"/>

    <!-- TEMPS REEL -->
    <lib-publication-details *ngIf="component.type === 'lib_publication_details'" [apiUrl]="component.apiUrl"
                             [componentId]="component.componentId"
                             [publicationId]="queryParams['publicationId']"></lib-publication-details>

    <lib-publication-timeline *ngIf="component.type === 'lib_publication_timeline'" [apiUrl]="component.apiUrl"
                              [componentId]="component.componentId"
                              [publicationId]="queryParams['publicationId']"></lib-publication-timeline>

    <lib-realtime-stats *ngIf="component.type === 'realtime_stats'" [apiUrl]="component.apiUrl"
      [goldUrl]="config.apiUrl + '/subscriptions'" [accountUrl]="config.apiUrl + '/account-manager-info'"
      [size]="component.size" [mapUrl]="config.apiUrl + '/map'" [componentId]="component.componentId"
      [loadingMessage]="component.loadingMessage" [publicationId]="queryParams['publicationId']"
      [mevDelay]="queryParams['mevDelay']" (segmentChange)="onRealTimeSegmentChange($event)" (comparedParutionChange)="onComparedParution($event)"
      (warehouseChange)="onRealTimeCurrentWarehouseChange($event)"
      (marketTypeChange)="onRealTimeCurrentMarketTypeChange($event)"></lib-realtime-stats>

    <lib-sales-points-stats *ngIf="component.type === 'sales_point_stats'" [apiUrl]="component.apiUrl"
                            [goldUrl]="config.apiUrl + '/subscriptions'"
                            [accountUrl]="config.apiUrl + '/account-manager-info'"
                            [size]="component.size" [componentId]="component.componentId"
                            [loadingMessage]="component.loadingMessage"
                            [publicationId]="queryParams['publicationId']"
                            [segmentId]="eventDataService.realtimeSegment"
                            [warehouseId]="eventDataService.realtimeCurrentWarehouse"
                            [marketType]="eventDataService.realtimeCurrentMarketType"
                            [comparedParution]="eventDataService.comparedParution"></lib-sales-points-stats>
    <!-- FIN TEMPS REEL -->

    <lib-hello *ngIf="component.type === 'hello'" [apiUrl]="component.apiUrl" [componentId]="component.componentId">
    </lib-hello>

    <lib-annual-sales *ngIf="component.type === 'revenues'" [apiUrl]="component.apiUrl"
                      [brandEditor]="eventDataService?.currentBrandEditor" [loadingMessage]="component.loadingMessage">
    </lib-annual-sales>

    <lib-parutions-list *ngIf="component.type === 'publications'" [apiUrl]="component.apiUrl"
                        [goldUrl]="config.apiUrl + '/subscriptions'"
                        [accountUrl]="config.apiUrl + '/account-manager-info'"
                        [brandEditor]="eventDataService?.currentBrandEditor"
                        [incidentsIndicatorFilter]="eventDataService.incidentsIndicatorFilter"
                        (filterChange)="onParutionFilterChange($event)"></lib-parutions-list>

    <lib-parutions-included *ngIf="component.type === 'parutions_incluses'" [apiUrl]="component.apiUrl"
      [title]="component.title" [size]="component.size" [loadingMessage]="component.loadingMessage" [filterMarket]="eventDataService.statisticsFilter.market"
      [componentId]="component.componentId" [multipleFilters]="inputService.filters" [compareParution]="eventDataService?.compareParution"
      (changeCompareParution)="onCompareParution($event)" (filterChange)="onParutionFilterChange($event)" (includeHsChange)="onIncludeHS($event)">
    </lib-parutions-included>

    <lib-account-incharge *ngIf="component.type === 'account_incharge'" [apiUrl]="component.apiUrl"
                          [brandEditor]="eventDataService?.currentBrandEditor"></lib-account-incharge>

    <lib-incidents-indicator *ngIf="component.type === 'indicator_homepage'" [apiUrl]="component.apiUrl"
                             [brandEditor]="eventDataService?.currentBrandEditor" [componentId]="component.componentId"
                             [currentIndicatorFilter]="eventDataService.incidentsIndicatorFilter"
                             [parutionListFilter]="eventDataService.parutionListFilter"
                             (clicked)="onIncidentsIndicatorClicked($event)">
    </lib-incidents-indicator>

    <lib-page-title *ngIf="component.type === 'page_title'" [page]="template" [apiUrl]="component.apiUrl"
                    [componentId]="component.componentId"></lib-page-title>

    <lib-incidents-overview *ngIf="component.type === 'alert'" [apiUrl]="component.apiUrl"
                            [mapUrl]="config.apiUrl + '/map'"
                            [brandEditor]="eventDataService?.currentBrandEditor"></lib-incidents-overview>

    <lib-update-message *ngIf="component.type === 'update_message'" [apiUrl]="component.apiUrl"></lib-update-message>

    <lib-frontpages *ngIf="component.type === 'frontpages'" [apiUrl]="component.apiUrl"></lib-frontpages>

    <lib-sales-statistic-page-filters *ngIf="component.type === 'sales_statistic_filter'" [baseUrl]="config.apiUrl" [apiUrl]="component.apiUrl"
      [loadingMessage]="component.loadingMessage" [componentId]="component.componentId" [size]="component.size"
      [brandEditor]="eventDataService?.currentBrandEditor" (titleChanged)="onFilterStatsTitleChange($event)"
      (slidingYearChanged)="onFilterStatsDateChange($event)" (marketChanged)="onFilterStatsMarketChange($event)" (toCompareParution)="onCompareParution($event)">
    </lib-sales-statistic-page-filters>

    <lib-sales-stats *ngIf="component.type === 'sales_stats'" [apiUrl]="component.apiUrl"
      [goldUrl]="config.apiUrl + '/subscriptions'" [accountUrl]="config.apiUrl + '/account-manager-info'"
      [loadingMessage]="component.loadingMessage" [componentId]="component.componentId" [size]="component.size"
      [includeHS]="eventDataService?.includeHS" [filterMarket]="eventDataService.statisticsFilter.market" [multipleFilters]="inputService.filters" [compareParution]="eventDataService?.compareParution">
    </lib-sales-stats>

    <lib-financial-stats *ngIf="component.type === 'financial_stats'" [apiUrl]="component.apiUrl"
      [loadingMessage]="component.loadingMessage" [componentId]="component.componentId" [size]="component.size"
      [multipleFilters]="inputService.filters" [includeHS]="eventDataService?.includeHS"
      [compareParution]="eventDataService?.compareParution"></lib-financial-stats>

    <lib-business-operations *ngIf="component.type === 'business_operations'" [apiUrl]="component.apiUrl"
                             [loadingMessage]="component.loadingMessage" [size]="component.size"
                             [multipleFilters]="inputService.filters"
                             [includeHS]="eventDataService?.includeHS"></lib-business-operations>

    <lib-sales-segment *ngIf="component.type === 'sales_segment'" [apiUrl]="component.apiUrl"
      [goldUrl]="config.apiUrl + '/subscriptions'" [accountUrl]="config.apiUrl + '/account-manager-info'"
      [loadingMessage]="component.loadingMessage" [componentId]="component.componentId" [size]="component.size"
      [multipleFilters]="inputService.filters" [includeHS]="eventDataService?.includeHS"
      [filterMarket]="eventDataService.statisticsFilter.market" [compareParution]="eventDataService?.compareParution">
    </lib-sales-segment>

    <lib-rankings *ngIf="component.type === 'rankings'" [apiUrl]="component.apiUrl"
      [loadingMessage]="component.loadingMessage" [size]="component.size"
      [multipleFilters]="inputService.filters" [includeHS]="eventDataService?.includeHS"
      [compareParution]="eventDataService?.compareParution"></lib-rankings>

    <lib-upcoming-parutions-list *ngIf="component.type === 'publications_upcoming'" [apiUrl]="component.apiUrl"
                                 [loadingMessage]="component.loadingMessage" [size]="component.size"
                                 [brandEditor]="eventDataService?.currentBrandEditor"
                                 [upComingIndicatorFilter]="eventDataService.upComingIndicatorFilter"
                                 (filterChange)="onUpComingFilterChange($event)"
    ></lib-upcoming-parutions-list>

    <lib-consolidation-parutions-list
      *ngIf="component.type === 'consolidation_publications'"
      [apiUrl]="component.apiUrl"
      [brandEditor]="eventDataService?.currentBrandEditor"
      [goldUrl]="config.apiUrl + '/subscriptions'"
      [accountUrl]="config.apiUrl + '/account-manager-info'"
      [loadingMessage]="component.loadingMessage"
      [size]="component.size"
    ></lib-consolidation-parutions-list>

    <lib-lifecycle-upcoming
      *ngIf="component.type === 'upcoming_summary'"
      [apiUrl]="component.apiUrl"
      [loadingMessage]="component.loadingMessage"
      [size]="component.size"
      [brandEditor]="eventDataService?.currentBrandEditor"
      [upComingFilter]="eventDataService.upComingIndicatorFilter"
      (indicatorClick)="onUpComingFilterChange($event)"
    ></lib-lifecycle-upcoming>

    <lib-ventes-scannees *ngIf="component.type === 'ventes_scannees'"
                         [apiUrl]="component.apiUrl"
                         [loadingMessage]="component.loadingMessage"
                         [size]="component.size"
                         [brandEditor]="eventDataService?.currentBrandEditor"
    ></lib-ventes-scannees>


    <ng-container *ngIf="component.type === 'homepage_tabs'">
      <lib-select-tabs class="tabs" [apiUrl]="component.apiUrl" [componentId]="component.componentId"
                       [currentPage]="template" [selectedTabValue]="queryParams['tab-' + component.componentId]"
                       (tabSelected)="onTabChanged($event, component.componentId)"></lib-select-tabs>
      <app-template [template]="queryParams['tab-' + component.componentId]" class="inner-template"></app-template>
    </ng-container>
  </div>
</div>
