import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

const TIMEOUT_IN_SECONDS = 10;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public url: string;

  public countdown: number;

  private countdownTimer: any;

  constructor(private router: Router, private zone: NgZone) {}

  ngOnInit(): void {
    this.countdown = TIMEOUT_IN_SECONDS;
    this.url = this.router.routerState.snapshot.url;

    this.countdownTimer = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(this.countdownTimer);
      }
    }, 1000);

    setTimeout(() => {
      this.zone.run(() =>
        this.router.navigate(['/'], {
          queryParams: { returnUrl: this.router.routerState.snapshot.url },
        })
      );
    }, TIMEOUT_IN_SECONDS * 1000);
  }
}
