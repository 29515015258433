<article class="container"
         [ngClass]="{ 'is-frontpage': isFrontpage, 'no-mirror': !isMirror && useMirror, 'is-modal': isModal }"
         (click)="goToParutionDetails(mevDelayDelta)">
  <div class="parution-cover" [ngStyle]="{ 'background-image': 'url(' + publication?.coverUrl + ')' }"
       [ngClass]="{'mirror-parution': isMirror, 'no-mirror-parution': !isMirror}" (mouseenter)="mouseHovering()"
       (mouseleave)="mouseLeft()">
    <div class="top-status" *ngIf="leftStatus || rightStatus">
      <span class="day-alert" *ngIf="mevDelayDelta && !isFromComparator">J{{ mevDelayDelta }}</span>
      <span class="day-alert" *ngIf="isFromComparator">{{ mevDelayDeltaComparator }}</span>
      <span class="comparaison-button"
            *ngIf="apiUrlForCompare && publication?.canCompare && publication?.displayCode?.canCompare === 1">
        <lib-comparator [apiUrl]="apiUrlForCompare + '/compare?publicationId=' + publication?.publicationId">
        </lib-comparator>
      </span>
    </div>
    <div class="bottom-status" *ngIf="leftStatus || rightStatus">
      <lib-button-gold
        *ngIf="publication?.displayCode && publication?.displayCode[leftStatus?.code] && publication?.displayCode[leftStatus?.code] > 1"
        [subscription]="publication?.displayCode?.soldRate" [text]="leftStatus?.label" [accountUrl]="accountUrl"
        [blur]="false" [center]="false" [goldUrl]="goldUrl" type="button"></lib-button-gold>
      <lib-publication-state class="left-status" [value]="leftStatus?.value" [label]="leftStatus?.label"
                             [isPercent]="leftStatus?.isPercent" [alertColor]="leftStatus?.alertColor"
                             [withCircle]="leftStatus?.withCircle"
                             [circleColor]="leftStatus?.circleColor" [icon]="leftStatus?.icon"
                             [tooltipContentType]="leftStatus?.tooltipContentType"
                             [tooltipContent]="leftStatus?.tooltipContent"
                             *ngIf="leftStatus && (!publication?.displayCode || !publication?.displayCode[leftStatus?.code] || publication?.displayCode[leftStatus?.code] === 1)">
      </lib-publication-state>
      <lib-publication-state class="right-status" [value]="rightStatus?.value" [label]="rightStatus?.label"
                             [isPercent]="rightStatus?.isPercent" [alertColor]="rightStatus?.alertColor"
                             [withCircle]="rightStatus?.withCircle" [circleColor]="rightStatus?.circleColor"
                             [icon]="rightStatus?.icon"
                             [tooltipContentType]="rightStatus?.tooltipContentType"
                             [tooltipContent]="rightStatus?.tooltipContent"
                             [tooltipPosition]="rightStatus?.tooltipPosition" [showTooltip]="showTooltip"
                             *ngIf="checkAnomalies(rightStatus?.value, rightStatus?.label)">
      </lib-publication-state>
    </div>
    <div *ngIf="!leftStatus && !rightStatus && subFamily && !isMirror" class="container-sub-family">
      <div class="sub-family">
        {{subFamily}}
      </div>
    </div>
  </div>
  <div class="parution-info">
    <div class="number-n-title" [ngClass]="{ 'is-comparator': isFromComparator }" *ngIf="leftStatus || rightStatus">
      {{ publication?.publicationId | RemoveAfterHyphenPipe }} • {{ publication?.name }} n°{{
      publication?.publicationNumber
      }}
      <ng-container *ngIf="publication?.price"> • {{ publication?.price | number: '.2':'fr-FR' }} €</ng-container>
    </div>
    <div class="number-n-title" [ngClass]="{ 'is-comparator': isFromComparator || isModal }"
         *ngIf="(isFrontpage || isModal) && !isMirror">
      {{ publication?.publicationId | RemoveAfterHyphenPipe }} • {{ publication?.name }}
      n°{{ publication?.publicationNumber }}
      <ng-container *ngIf="publication?.price"> • {{ publication?.price | number: '.2':'fr-FR' }} €</ng-container>
    </div>
    <div class="mev-dates" *ngIf="!isMirror">
      <span class="promo" *ngIf="publication?.isPromo">{{ 'promo' }}</span>
      <ng-container *ngIf="publication?.sellingEndDate; else noEndDate">
        <span *ngIf="!isHomologous">MEV du {{ publication?.sellingStartDate | date: 'dd/MM' }} au
          {{ publication?.sellingEndDate | date: 'dd/MM' }}</span>
        <span *ngIf="isHomologous">MEV du {{ publication?.sellingStartDate | date: 'dd/MM/yy' }} au
          {{ publication?.sellingEndDate | date: 'dd/MM/yy' }}</span>
      </ng-container>
      <ng-template #noEndDate>
        <span>MEV à partir du {{ publication?.sellingStartDate | date: 'dd/MM' }}</span>
      </ng-template>
    </div>
    <div class="return-date" *ngIf="publication?.returnRate">
      {{ publication?.returnRate | percent }}
      <span>Tx de retour</span>
    </div>
    <div class="cde-reassort" *ngIf="publication?.ordersCount">
      {{ publication?.ordersCount }} <span>ex. cde Réassort</span>
    </div>
  </div>
</article>
