<ng-container *ngIf="loading">
  {{ loadingMessage }}
</ng-container>
<div *ngIf="!loading">
  <lib-nav-tabs
    [tabs]="tabs"
    background="yellow"
    (tabSelected)="onSelectTab($event)"
    [updateMessage]="true"
  ></lib-nav-tabs>
</div>
