<div class="global-container">
  <div class="container">
    <div class="title-container">
      <span class="title">
        comparer:
      </span>
    </div>
    <div class="parution-container border">
      <div class="parution">
        <div class="cover">
          <img [src]="currentParution?.coverUrl"/>
        </div>
        <div class="infos-container">
          <div class="name-container">
            <span *ngIf="currentParution?.publicationId">{{ currentParution.publicationId | RemoveAfterHyphenPipe }}
              •</span>
            <span *ngIf="currentParution?.name" class="name"> {{ currentParution.name }} </span>
            <span *ngIf="currentParution?.publicationNumber">n°{{ currentParution.publicationNumber }}</span>
          </div>
          <div class="price-mev-container">
            <div class="price-container">
              <div class="subtitle">
                Prix
              </div>
              <div class="value" *ngIf="currentParution?.price">
                {{ currentParution.price | number: '.2':'fr-FR' }} €
              </div>
            </div>
            <div class="mev-container">
              <div class="subtitle">
                Dates de MEV:
              </div>
              <div class="value" *ngIf="currentParution?.sellingStartDate && currentParution?.sellingEndDate">
                Du {{ currentParution.sellingStartDate | date: 'dd/MM/yy' }} au
                {{ currentParution.sellingEndDate | date: 'dd/MM/yy' }}
              </div>
            </div>
          </div>
          <div class="ref-container">
            <span class="type">
              Numéro étudié
            </span>
          </div>
          <div *ngIf="additonalModifyButton" class="modify-button-container">
            <div id="bis-responsive-compare-selector" class="modify-button">
              <span class="modify" (click)="openSelect('bis-responsive-compare-selector', true)">modifier</span>
              <div *ngIf="bisSelectIsOpen" class="options-container" (blur)="closeSelect()">
                <div class="option" (click)="onChangeComparedParution(option.value, true)"
                     *ngFor="let option of selectOptions">
                  <span class="option-label">{{ option.label}} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parution-container">
      <div class="parution">
        <div class="cover">
          <img [src]="comparedParution?.coverUrl"/>
        </div>
        <div class="infos-container">
          <div class="name-container">
            <span *ngIf="comparedParution?.publicationId">{{ comparedParution.publicationId | RemoveAfterHyphenPipe }}
              •</span>
            <span *ngIf="comparedParution?.name" class="name"> {{ comparedParution.name }} </span>
            <span *ngIf="comparedParution?.publicationNumber">n°{{ comparedParution.publicationNumber }}</span>
          </div>
          <div class="price-mev-container">
            <div class="price-container">
              <div class="subtitle">
                Prix
              </div>
              <div class="value" *ngIf="comparedParution?.price">
                {{ comparedParution.price | number: '.2':'fr-FR' }} €
              </div>
            </div>
            <div class="mev-container">
              <div class="subtitle">
                Dates de MEV:
              </div>
              <div class="value" *ngIf="comparedParution?.sellingStartDate && comparedParution?.sellingEndDate">
                Du {{ comparedParution.sellingStartDate | date: 'dd/MM/yy' }} au
                {{ comparedParution.sellingEndDate | date: 'dd/MM/yy' }}
              </div>
            </div>
          </div>
          <div class="ref-container">
            <span class="type">
              Numéro {{ comparedParutionType }}
            </span>
          </div>
          <div class="modify-button-container">
            <div id="compare-selector" class="modify-button">
              <span class="modify" (click)="openSelect('compare-selector')">modifier</span>
              <div *ngIf="selectIsOpen" class="options-container" (blur)="closeSelect()">
                <div class="option" (click)="onChangeComparedParution(option.value)"
                     *ngFor="let option of selectOptions">
                  <span class="option-label">{{ option.label}} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
