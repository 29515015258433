var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-US-POSIX';
import localeFr from '@angular/common/locales/fr';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app.config';
import { LoggerService } from './services/common/logger.service';
import { ToastService } from './services/common/toast.service';
import { UserService } from './services/data/user.service';
import { GTMService } from './services/common/gtm.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, translate, log, config, GTM, userService, toastService) {
        var _this = this;
        this.router = router;
        this.translate = translate;
        this.log = log;
        this.config = config;
        this.GTM = GTM;
        this.userService = userService;
        this.toastService = toastService;
        this.log.setLevel(config.logLevel);
        this.log.debug('Application started');
        // French language by default.
        translate.setDefaultLang('fr');
        this.onLangChanged('fr');
        this.translate.onLangChange.subscribe(function (event) {
            _this.onLangChanged(event.lang);
        });
        this.log.debug('CONFIG', 'env', this.config.env);
        // // Hack to reuse the reload on the current component
        // this.router.routeReuseStrategy.shouldReuseRoute = () => {
        //   return false;
        // };
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, dataLayer, toPush, ex_1, dataLayer, toPush;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.addGTM();
                        return [4 /*yield*/, this.userService.getCurrentUser().toPromise()];
                    case 1:
                        user = _a.sent();
                        dataLayer = window['dataLayer'] || [];
                        toPush = this.GTM.createDataLayerObject(user.userName);
                        dataLayer.push(toPush);
                        this.log.debug('User authenticated', user);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.log.debug('User not authenticated');
                        dataLayer = window['dataLayer'] || [];
                        toPush = this.GTM.createDataLayerObject(null);
                        dataLayer.push(toPush);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.onLangChanged = function (lang) {
        // LTR languages (Left To Right)
        var rtlLangs = ['ar'];
        this.log.debug('Language changed', lang);
        switch (lang) {
            case 'fr':
                registerLocaleData(localeFr, 'fr');
                break;
            // case 'ar':
            //   registerLocaleData(localeAr, 'ar');
            //   break;
            default:
                registerLocaleData(localeEn, 'en-US');
                break;
        }
    };
    AppComponent.prototype.addGTM = function () {
        var head = document.getElementsByTagName('head').item(0);
        var script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.innerText = "(function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-MXRJ3BQ');";
        head.appendChild(script);
    };
    return AppComponent;
}());
export { AppComponent };
