import { Injectable, OnInit } from '@angular/core';
import { MultipleFilters } from '../data/statistics-filter.model';
import { EventDataService } from '../data/event-data.service';

@Injectable({
  providedIn: 'root',
})
// Service created to group parameters together, smart update of single value
export class InputService {

  filters: MultipleFilters;

  constructor(private eventDataService: EventDataService) {
  }

  public updateBrandEditor (brandEditor: any) {
    this.filters = {
      brandEditor: brandEditor ? brandEditor : null,
      titleId: brandEditor ? this.eventDataService.statisticsFilter.title : null,
      periodId: brandEditor ? this.eventDataService.statisticsFilter.date : null,
    };
  }

  public updateTitle (titleId: string) {
    this.filters = {
      brandEditor: this.eventDataService.currentBrandEditor,
      titleId: titleId ? titleId : null,
      periodId: titleId ? this.eventDataService.statisticsFilter.date : null,
    };
  }

  public updatePeriod (periodId: string) {
    this.filters = {
      brandEditor: this.eventDataService.currentBrandEditor,
      titleId: this.eventDataService.statisticsFilter.title,
      periodId: periodId ? periodId : null,
    };
  }


}
