<div class="panel-portal-editor">
  <div class="portal-editor-title">Pressmine</div>

  <form [formGroup]="form" class="form-login">
    <lib-input
      #username
      type="text"
      inputName="username"
      class="input-username"
      placeholderName="Identifiant"
      controlName="userName"
      [group]="form"
      [disabled]="isLogging"
    ></lib-input>

    <lib-input
      type="password"
      inputName="password"
      placeholderName="Mot de passe"
      controlName="password"
      [group]="form"
      [disabled]="isLogging"
    ></lib-input>

    <lib-button
      type="submit"
      class="button-connection"
      [text]="isLogging ? 'Connexion en cours...' : 'Connexion'"
      [disabled]="isLogging"
      (click)="login()"
    ></lib-button>

    <!-- <a href="https://www.w3schools.com" class="forget-password-text">
      Mot de passe oublié ?
    </a> -->
  </form>
</div>
