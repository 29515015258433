<ng-container>
  <lib-loader [loaderText]="loadingMessage" *ngIf="isLoading; else isLoaded"></lib-loader>
</ng-container>
<ng-template #isLoaded>
  <div class="container">
    <ng-container *ngFor="let arrow of arrows; let i = index">
      <lib-lifecycle-arrow
        class="arrow"
        [arrowData]="arrow"
        [position]="i === 0 ? 'first' : i === arrows.length - 1 || i === arrows.length - 2 ? 'last' : 'middle'"
        [withoutArrow]="i === arrows.length - 1"
        [color]="arrowColors[i]"
        (arrowClicked)="handleIndicatorClick($event, i)"
      >
      </lib-lifecycle-arrow>
      <div
        class="arrow-chevron-mobile"
        [ngClass]="{ 'last-item': i === arrows.length - 2 }"
        *ngIf="i !== arrows.length - 1"
      >
        >
      </div>
    </ng-container>
  </div>
</ng-template>
