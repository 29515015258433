import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfig} from 'src/app/app.config';
import {AuthenticationService} from 'src/app/services/common/authentication.service';
import {LoggerService} from 'src/app/services/common/logger.service';
import {EventDataService} from 'src/app/services/data/event-data.service';
import {User} from '../../services/data/user.model';
import {UserService} from '../../services/data/user.service';
import {Subscription} from 'rxjs';
import { GTMService } from '../../services/common/gtm.service';
import { InputService } from '../../services/common/input.service';

@Component({
  selector: 'app-template-master',
  templateUrl: './template-master.component.html',
  styleUrls: ['./template-master.component.scss'],
})
export class TemplateMasterComponent implements OnInit, OnDestroy {
  @ViewChild(Component)
  compHost;

  public template: string;
  public currentUser: User;
  public templateTree;
  public canSwitchBrand: boolean;

  public data = {};

  private paramMapSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private log: LoggerService,
    private userService: UserService,
    private authService: AuthenticationService,
    private GTM: GTMService,
    public config: AppConfig,
    public eventDataService: EventDataService,
    public inputService: InputService,
  ) {
  }

  async ngOnInit() {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    const dataLayer = window['dataLayer'] || [];
    const toPush = this.GTM.createDataLayerObject(this.currentUser.userName);
    dataLayer.push(toPush);
    if (this.currentUser && this.currentUser.groups && this.currentUser.groups.editors && this.currentUser.groups.editors.length > 0) {
      this.eventDataService.currentBrandEditor.editor = {
        value: this.currentUser.groups.editors[0].groupId.toString(),
        label: this.currentUser.groups.editors[0].name,
      }
    }
    if (!this.paramMapSubscription) {
      this.paramMapSubscription = this.route.paramMap.subscribe(async params => {
        this.template = params.get('template');
      });
    }
  }

  ngOnDestroy(): void {
    this.paramMapSubscription.unsubscribe();
  }

  onBrandChanged(args) {
    if (this.eventDataService.hasCompareParution()) {
      this.eventDataService.statisticsFilterDate = null;
    }
    this.eventDataService.currentBrandEditor = {
      brand: args && args.brand ? {value: args.brand.value, label: args.brand.label} : {
        value: null,
        label: null
      },
      editor: args && args.editor ? {value: args.editor.value, label: args.editor.label} : {
        value: null,
        label: null
      }
    };
    // this.eventDataService.statisticsFilterTitle = null;
  }

  onEditorChanged(args) {
    if (this.eventDataService.hasCompareParution()) {
      this.eventDataService.statisticsFilterDate = null;
    }
    this.eventDataService.currentBrandEditor = {
      brand: {
        value: null,
        label: null
      },
      editor: args && args.editor ? {value: args.editor.value, label: args.editor.label} : {
        value: null,
        label: null
      }
    };
  }

  onLogoutClicked() {
    this.authService.logout();
    this.router.navigate(['/login']);
    // FIXME : Should NOT do that, I know :(
    window.location.replace('/login');
  }

  onPageChanged(evt) {
    this.log.debug('onPageChanged', evt);
    this.router.navigate([`/page/${evt}`]);
    this.loadTemplate(evt);
  }

  loadTemplate(template) {
    // const template = this.template;
    this.template = null;
    setTimeout(() => {
      this.template = template; // template;
    });
  }

  /**
   * Called when the user changes the current page in order whether to disable the header select
   */
  displayHeaderSelect(event) {
    this.canSwitchBrand = event;
  }

  // New part to group all parameters and avoid multiple API calls in children components (due to setter)
  onBrandEditorChanged(event) {
    this.inputService.updateBrandEditor(event);
  }
}
