import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-US-POSIX';
import localeFr from '@angular/common/locales/fr';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app.config';
import { LoggerService } from './services/common/logger.service';
import { ToastService } from './services/common/toast.service';
import { UserService } from './services/data/user.service';
import { GTMService } from './services/common/gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private translate: TranslateService,
    public log: LoggerService,
    private config: AppConfig,
    private GTM: GTMService,
    private userService: UserService,
    public toastService: ToastService,
  ) {
    this.log.setLevel(config.logLevel);
    this.log.debug('Application started');

    // French language by default.
    translate.setDefaultLang('fr');
    this.onLangChanged('fr');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.onLangChanged(event.lang);
    });

    this.log.debug('CONFIG', 'env', this.config.env);

    // // Hack to reuse the reload on the current component
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // };
  }

  async ngOnInit(): Promise<void> {
    try {
      this.addGTM();
      const user = await this.userService.getCurrentUser().toPromise();
      const dataLayer = window['dataLayer'] || [];
      const toPush = this.GTM.createDataLayerObject(user.userName);
      dataLayer.push(toPush);
      this.log.debug('User authenticated', user);
    } catch (ex) {
      this.log.debug('User not authenticated');
      const dataLayer = window['dataLayer'] || [];
      const toPush = this.GTM.createDataLayerObject(null);
      dataLayer.push(toPush);
    }
  }

  onLangChanged(lang: string): any {
    // LTR languages (Left To Right)
    const rtlLangs = ['ar'];

    this.log.debug('Language changed', lang);

    switch (lang) {
      case 'fr':
        registerLocaleData(localeFr, 'fr');
        break;

      // case 'ar':
      //   registerLocaleData(localeAr, 'ar');
      //   break;

      default:
        registerLocaleData(localeEn, 'en-US');
        break;
    }
  }

  private addGTM() {
    const head = document.getElementsByTagName('head').item(0);
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerText = `(function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-MXRJ3BQ');`;
    head.appendChild(script);
  }
}
