import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../data/user.service';
import { LoggerService } from './logger.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private log: LoggerService, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const user = await this.userService.getCurrentUser().toPromise();
      this.log.debug('GUARD', user);
      if (user) {
        return true;
      }
    } catch (ex) {}

    // Not logged in => redirect to the login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url },
    });
  }
}
