import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from './services/common/error.service';
import { LoggerService } from './services/common/logger.service';
import { ToastService } from './services/common/toast.service';
import { UserService } from './services/data/user.service';
import { AppConfig } from './app.config';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private zone: NgZone,
    private errorService: ErrorService,
    private log: LoggerService,
    private appConfig: AppConfig
  ) {}

  handleError(error: Error | HttpErrorResponse | any) {
    if (error.rejection) {
      this.handleError(error.rejection);
      return;
    }

    const toastService: ToastService = this.injector.get(ToastService);
    const userService: UserService = this.injector.get(UserService);

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        toastService.showError('Veuillez vérifier votre connexion Internet.');
        return;
      } else {
        // Handle Http Error (error.status === 401, 403, 404...)
        switch (error.status) {
          case 401:
          case 403:
            userService.currentUser = null;
            const router = this.injector.get(Router);
            toastService.showError(`Votre session a expiré. Merci de vous authentifier.`);
            this.zone.run(() =>
              router.navigate(['/login'], {
                queryParams: { returnUrl: router.routerState.snapshot.url },
              })
            );
            break;

          default:
            this.log.error(error);
            toastService.showError(
              this.errorService.getErrorMessage(
                this.appConfig.isProduction.toString() === 'true' ? 'Calcul en cours.' : error
              )
            );
            return;
        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      //  router.navigate(['/error'], { queryParams: {error: error} });
      // alert(`${error.message}`);

      this.log.error(error);
      toastService.showError(
        this.errorService.getErrorMessage(
          this.appConfig.isProduction.toString() === 'true' ? 'Une erreur est survenue.' : error
        )
      );
      return;
    }
    // // Log the error anyway
    // console.error(error);
  }
}
