<ng-container>
  <lib-loader [loaderText]="loadingMessage" loaderSize="big" *ngIf="isLoading; else isLoaded"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div #scrollAnchor class="parutions-list" *ngIf="parutionsList && parutionsList.publicationsInPreparation">
    <div class="parutions-list-header">
      <div class="parutions-list-header-top">
        <div class="parutions-list-header-top-title">
          <span class="parutions-list-header-top-nbpublications">{{
            parutionsList.publicationsInPreparation.length
          }}</span>
          parutions à venir <span class="display-on-desktop">en ce moment</span>
        </div>
      </div>
      <div class="parutions-list-header-filters">
        <div class="parutions-list-header-filters-global">
          <lib-select
            [options]="parutionsList.filters.filterByItems"
            [model]="savedFilter"
            placeholder="Filter par"
            ngClassName="filter-select"
            clearable="true"
            (change)="onFilterByItems($event)"
          ></lib-select>
        </div>
        <div class="parutions-list-header-filters-date">
          <lib-select
            [options]="parutionsList.filters.orderByItems"
            placeholder="Trier par"
            ngClassName="filter-select"
            clearable="true"
            (change)="onOrderByItems($event)"
          ></lib-select>
        </div>
      </div>
    </div>
    <div class="parutions-list-display">
      <ng-container *ngFor="let publication of publicationsPages[currentPage - 1]; let i = index">
        <lib-upcoming-parution
          *ngIf="publication.alerts.length"
          class="parutions-list-display-item"
          [upcomingPublication]="publication"
          [leftStatus]="publication.status"
          [leftStatusColor]="getStatusColor(publication)"
          [rightStatus]="{
            value: publication.alerts.length,
            label: 'Anomalies',
            icon: 'info',
            tooltipContentType: 'list',
            tooltipContent: getAlertMessages(publication),
            tooltipPosition: 'top',
            alertColor: 'orange'
          }"
        >
        </lib-upcoming-parution>
        <lib-upcoming-parution
          *ngIf="publication.alerts.length === 0 && publication.status !== 'Réglable'"
          class="parutions-list-display-item"
          [upcomingPublication]="publication"
          [leftStatus]="publication.status"
          [leftStatusColor]="getStatusColor(publication)"
        >
        </lib-upcoming-parution>
        <lib-upcoming-parution
          *ngIf="publication.alerts.length === 0 && publication.status === 'Réglable'"
          class="parutions-list-display-item"
          [upcomingPublication]="publication"
          [leftStatus]="publication.status"
          [leftStatusColor]="getStatusColor(publication)"
          [rightStatus]="{ value: publication.nbSettledDistributors, label: 'diffuseurs réglés' }"
        >
        </lib-upcoming-parution>
      </ng-container>
    </div>
    <lib-pagination
      [numberOfItems]="parutionsList.publicationsInPreparation.length"
      [itemsPerPage]="nbItemPerPage"
      [currentPage]="currentPage"
      (pageChange)="paginationPageChange($event)"
      *ngIf="publicationsPages && publicationsPages.length > 1"
    ></lib-pagination>
  </div>
</ng-template>
