import { EventDataService } from '../data/event-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../data/event-data.service";
var InputService = /** @class */ (function () {
    function InputService(eventDataService) {
        this.eventDataService = eventDataService;
    }
    InputService.prototype.updateBrandEditor = function (brandEditor) {
        this.filters = {
            brandEditor: brandEditor ? brandEditor : null,
            titleId: brandEditor ? this.eventDataService.statisticsFilter.title : null,
            periodId: brandEditor ? this.eventDataService.statisticsFilter.date : null,
        };
    };
    InputService.prototype.updateTitle = function (titleId) {
        this.filters = {
            brandEditor: this.eventDataService.currentBrandEditor,
            titleId: titleId ? titleId : null,
            periodId: titleId ? this.eventDataService.statisticsFilter.date : null,
        };
    };
    InputService.prototype.updatePeriod = function (periodId) {
        this.filters = {
            brandEditor: this.eventDataService.currentBrandEditor,
            titleId: this.eventDataService.statisticsFilter.title,
            periodId: periodId ? periodId : null,
        };
    };
    InputService.ngInjectableDef = i0.defineInjectable({ factory: function InputService_Factory() { return new InputService(i0.inject(i1.EventDataService)); }, token: InputService, providedIn: "root" });
    return InputService;
}());
export { InputService };
