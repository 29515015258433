<ng-container>
  <lib-loader [loaderText]="loadingMessage" loaderSize="big" *ngIf="isLoading; else isLoaded"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div #scrollAnchor class="parutions-list" *ngIf="parutionsList && parutionsList.publications">
    <div class="parutions-list-header">
      <div class="parutions-list-header-top">
        <div class="parutions-list-header-top-title">
          <span class="parutions-list-header-top-nbpublications">{{
            parutionsList.nbPublications
            }}</span>
          parutions en consolidation
        </div>
      </div>
      <div class="parutions-list-header-filters">
        <div class="parutions-list-header-filters-global">
          <lib-select
            [options]="parutionsList.filters.filterByItems"
            [model]="savedFilter"
            placeholder="Filter par"
            ngClassName="filter-select"
            clearable="true"
            (change)="onFilterByItems($event)"
          ></lib-select>
        </div>
        <div class="parutions-list-header-filters-date">
          <lib-select
            [options]="parutionsList.filters.orderByItems"
            placeholder="Trier par"
            ngClassName="filter-select"
            clearable="true"
            (change)="onOrderByItems($event)"
          ></lib-select>
        </div>
      </div>
    </div>
    <div class="parutions-list-display">
      <ng-container *ngFor="let publication of publicationsPages[currentPage - 1]; let i = index">
        <lib-parution
          *ngIf="setPublicationToSale(publication) > 0 && !publication.nbWarehousesInAlert"
          class="parutions-list-display-item"
          [publication]="publication"
          [goldUrl]="goldUrl"
          [accountUrl]="accountUrl"
          [leftStatus]="{
            value: publication.soldRate,
            code: 'soldRate',
            label: 'Tx vente',
            isPercent: true,
            withCircle: true,
            circleColor: 'yellow',
            alertColor: 'white'
          }"
          [rightStatus]="{
            value: publication.ruptureRate,
            label: 'Tx rupture',
            isPercent: true,
            icon: getIconRupture(publication),
            alertColor: getColorRupture(publication)
          }"
          [apiUrlForCompare]="apiUrl"
        >
        </lib-parution>
      </ng-container>
    </div>
    <lib-pagination
      [numberOfItems]="parutionsList.publications.length"
      [itemsPerPage]="nbItemPerPage"
      [currentPage]="currentPage"
      (pageChange)="paginationPageChange($event)"
      *ngIf="publicationsPages && publicationsPages.length > 1"
    ></lib-pagination>
  </div>
</ng-template>
