<header class="header-container">
  <div class="header">
    <lib-header
      apiUrl="{{ config.apiUrl }}/header"
      [brand]="eventDataService.currentBrandEditor.brand.label"
      [selectEnabled]="canSwitchBrand"
      [currentPage]="template"
      [currentUser]="currentUser"
      (brandChanged)="onBrandChanged($event)"
      (editorChanged)="onEditorChanged($event)"
      (logoutClicked)="onLogoutClicked()"
      (changePage)="onPageChanged($event)"
      (brandEditorChanged)="onBrandEditorChanged($event)"
    ></lib-header>
  </div>
</header>

<main class="main">
  <app-template [template]="template" (canSwitchBrand)="displayHeaderSelect($event)"></app-template>
</main>

<footer class="footer-container">
  <div class="footer">
    <lib-footer apiUrl="{{ config.apiUrl }}/footer"></lib-footer>
  </div>
</footer>
