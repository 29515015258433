export class StatisticsFilter {
  title: string;
  market: string;
  date: string;
}

export class MultipleFilters {
  brandEditor: {
    brand: {
      value: string;
      label: string
    },
    editor: {
      value: string;
      label: string
    };
  };
  titleId: string;
  periodId: string;
}
