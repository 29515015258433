<div class="sales-container" [ngClass]="{'text-blur': subscription > 1}">
  <lib-button-gold
    *ngIf="subscription > 1"
    [goldUrl]="goldUrl"
    [text]="stat?.label"
    [blur]="true"
    [center]="true"
    [accountUrl]="accountUrl"
    [subscription]="subscription"
    type="button"
  ></lib-button-gold>
  <ng-container>
    <lib-loader [loaderText]="loadingMessage" *ngIf="isLoading; else isLoaded"></lib-loader>
  </ng-container>

  <ng-template #isLoaded>
    <div class="sales-container-header">
      <div class="sales-container-header-major">
        <div *ngIf="stat?.isPercent" class="sales-container-header-major-circle">
          <lib-percentage-circle *ngIf="subscription === 1" [percentage]="stat?.value * 100" color="blue-stats" size="46"></lib-percentage-circle>
        </div>
        <ng-container *ngIf="stat?.isPercent">
          <div *ngIf="stat?.isFloat" class="sales-container-header-major-part">
            {{ stat?.value * 100 | number: '.0-1':'fr-FR' }}
          </div>
          <div *ngIf="!stat?.isFloat" class="sales-container-header-major-part">
            {{ stat?.value * 100 | number: '.0-0':'fr-FR' }}
          </div>
          <div class="sales-container-header-major-indicator">%</div>
        </ng-container>
        <ng-container *ngIf="!stat?.isPercent">
          <div *ngIf="stat?.isFloat" class="sales-container-header-major-part">
            {{ stat?.value / 1000 | number: '.0-1':'fr-FR' }}
          </div>
          <div *ngIf="!stat?.isFloat" class="sales-container-header-major-part">
            {{ stat?.value / 1000 | number: '.0-0':'fr-FR' }}
          </div>
          <div class="sales-container-header-major-indicator">k</div>
        </ng-container>
      </div>
      <div class="sales-container-header-label">{{ stat?.label }}</div>
    </div>
    <div class="sales-container-footer">
      <div
        class="sales-container-footer-left"
        [ngClass]="{ bordered: (marketId === 'family' && stat?.family) || (marketId === 'market' && stat?.market) }"
      >
        <div *ngIf="!compareParution?.showCompare">Evol. Année -1</div>
        <div *ngIf="compareParution?.showCompare">Evol. parution comparée</div>
        <div *ngIf="stat?.isPercent" class="sales-container-footer-left-datas">
          <div>{{ stat?.mirror * 100 | addSign }}</div>
          <div>pts</div>
        </div>
        <div *ngIf="!stat?.isPercent" class="sales-container-footer-left-datas">
          <div>{{ stat?.mirror * 100 | addSign }}</div>
          <div>%</div>
        </div>
        <div *ngIf="marketShare">
          <div *ngIf="marketId === 'market'">Marché SPRINT</div>
          <div *ngIf="marketId !== 'market'">Sous-famille</div>
        </div>
      </div>
      <div *ngIf="marketId === 'market' && stat?.market" class="sales-container-footer-right">
        <div *ngIf="stat.isPercent">Marché SPRINT</div>
        <div *ngIf="!stat.isPercent">Evol. marché</div>
        <div *ngIf="stat.isPercent" class="sales-container-footer-right-datas">
          {{ stat.market * 100 | number: '.0-1':'fr-FR' }}%
        </div>
        <div *ngIf="!stat.isPercent" class="sales-container-footer-right-datas">{{ stat.market * 100 | addSign }}%</div>
      </div>
      <div *ngIf="marketId === 'family' && stat?.family" class="sales-container-footer-right">
        <div>Sous-famille</div>
        <div *ngIf="stat.isPercent" class="sales-container-footer-right-datas">
          {{ stat.family * 100 | number: '.0-1':'fr-FR' }}%
        </div>
        <div *ngIf="!stat.isPercent" class="sales-container-footer-right-datas">{{ stat.family * 100 | addSign }}%</div>
      </div>
    </div>
  </ng-template>
</div>
