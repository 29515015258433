import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(private router: Router, private http: HttpClient, private config: AppConfig) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 503) {
          this.http.get<any>(`${this.config.apiUrl}/maintenance`).subscribe((res: any) => {
            if (res.status) {
              this.router.navigate(['/maintenance']);
            }
          });
        }
        return throwError(err);
      }));
  }
}
