import * as i0 from "@angular/core";
var ErrorService = /** @class */ (function () {
    function ErrorService() {
    }
    ErrorService.prototype.getErrorMessage = function (error) {
        var errorMessage;
        if (typeof error === 'string') {
            errorMessage = error;
        }
        else if (error.rejection) {
            errorMessage = "Erreur " + error.rejection.status + " : " + this.getErrorMessage(error.rejection);
        }
        else if (error.error) {
            if (error.error.error && error.error.error.message) {
                errorMessage = '' + error.error.error.message;
            }
            else if (error.error.message) {
                errorMessage = '' + error.error.message;
            }
        }
        if (!errorMessage) {
            errorMessage = error.message;
        }
        return errorMessage;
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
