<div class="lib-hello-wrapper">
    <ng-container *ngIf="isLoading;else isLoaded">
        <lib-loader [loaderText]="loadingMessage"></lib-loader>
    </ng-container>
    <ng-template #isLoaded>
        <div class="lib-hello-content-wrapper">
            <div class="lib-hello-hello-text">Bonjour,</div>
            <div class="lib-hello-message">{{ helloWSResponse.welcomeMessage }}</div>
        </div>
    </ng-template>
</div>
