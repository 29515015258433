<div id="scrollAnchor" class="container">
  <div class="parutions-list-overlay"></div>
  <ng-container *ngIf="!isLoading; else noLoading">
    <div class="parutions-list">
      <div class="close-button" (click)="onCloseModal()">X</div>
      <div class="parutions-list-header">
        <div class="parutions-list-header-top">
          <div class="parutions-list-header-top-title">
            <span class="parutions-list-header-top-nbpublications">{{ parutionsList.publicationNumber }} numéros</span>
          </div>
          <div *ngIf="currentParution && !newRefParution" class="parutions-list-header-top-update">
            Choisissez la parution que vous souhaitez comparer à {{ currentParution.name }}
            n°{{ currentParution.publicationNumber }}
          </div>
          <div *ngIf="(currentTitle && !currentParution) || newRefParution" class="parutions-list-header-top-update">
            Choisissez la parution {{currentTitle}} que vous souhaitez analyser
          </div>
        </div>
      </div>
      <div class="parutions-list-display">
        <ng-container *ngFor="let publication of publicationsPages[currentPage - 1]">
          <lib-parution class="parutions-list-display-item" [isModal]="true" isHomologous="true" (click)="selectParution(publication)"
                        [publication]="publication">
          </lib-parution>
        </ng-container>
      </div>
      <div class="pagination-container">
        <lib-pagination [numberOfItems]="parutionsList.publications.length" [itemsPerPage]="nbItemPerPage"
                        [currentPage]="currentPage" [fromModal]="true" (pageChange)="paginationPageChange($event)"
                        *ngIf="publicationsPages && publicationsPages.length > 1"></lib-pagination>
      </div>
    </div>
  </ng-container>
  <ng-template #noLoading>
    <lib-loader class="loader" loaderTheme="light"></lib-loader>
  </ng-template>
</div>
