import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as log from 'loglevel';
// import * as Raven from 'raven-js';

@Injectable()
export class AppConfig {
  // Properties from config file
  env: string;
  isProduction = true;
  logLevel: log.LogLevelDesc = 'error';
  apiUrl: string;

  // End Properties from config file

  public isConfigLoaded: false;

  private configUrl: string;

  constructor(private http: HttpClient) {
    this.configUrl = '/config.json';
  }

  /**
   * Load the config.json file at the start of the application.
   */
  public load(): Promise<object> {
    return new Promise(async (resolve, reject) => {
      if (this.isConfigLoaded) {
        resolve(this);
      } else {
        try {
          // const config = await http.get(this.configUrl).toPromise();
          const config = await this.loadConfig();

          // Copy all the config properties into the AppConfig instance,
          // so we can access values using config.propertyExample.
          Object.assign(this, config);

          resolve(this);
        } catch (ex) {
          log.error(`Configuration file error: ${ex}`);
          reject(ex);
        }
      }
    });
  }

  private async loadConfig(): Promise<any> {
    const data = await this.http.get(this.configUrl).toPromise();
    log.debug('loadConfig (FROM WS)', data);
    return data;
  }
}
