<ul class="page-list">
  <li class="page btn" [ngClass]="{'disabled': currentPage === 1, 'modal-btn-prev': fromModal, 'btn-prev': !fromModal}"
    (click)="setCurrentPage(currentPage-1)"></li>

  <li class="page" *ngFor="let page of firstPages">
    <a class="content" (click)="setCurrentPage(page)" [ngClass]="{ active: currentPage === page }">{{ page }}</a>
  </li>

  <ng-container *ngIf="middlePages">
    <li class="page">
      <a class="content" (click)="setCurrentPage(currentPage-1)">...</a>
    </li>

    <li class="page" *ngFor="let page of middlePages">
      <a class="content" (click)="setCurrentPage(page)" [ngClass]="{ active: currentPage === page }">{{ page }}</a>
    </li>

    <li class="page">
      <a class="content" (click)="setCurrentPage(currentPage+1)">...</a>
    </li>
  </ng-container>

  <ng-container *ngIf="lastPages">
    <li class="page" *ngIf="!middlePages">
      <a class="content disabled">...</a>
    </li>

    <li class="page" *ngFor="let page of lastPages">
      <a class="content" (click)="setCurrentPage(page)" [ngClass]="{ active: currentPage === page }">{{ page }}</a>
    </li>
  </ng-container>

  <li class="page btn" [ngClass]="{'disabled': currentPage === numberOfPage, 'modal-btn-next': fromModal, 'btn-next': !fromModal}"
    (click)="setCurrentPage(currentPage+1)"></li>
</ul>

<!-- <pre> currentPage {{ currentPage }}</pre>
<pre> firstPages {{ firstPages | json }}</pre>
<pre> middlePages {{ middlePages | json }}</pre>
<pre> lastPages {{ lastPages | json }}</pre>
<pre> length {{ this.numberOfDisplayedPage / 2 }}</pre>
<pre> numberOfPage {{ this.numberOfPage }}</pre> -->
