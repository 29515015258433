<div class="container" (click)="stopClickEventParution($event)">
  <div class="container-modal-overlay"></div>
  <div class="container-modal-content">
    <ng-container *ngIf="!isLoading; else Loading">
      <div class="container-modal-content-header">
        <div class="container-modal-content-title">
          <span>{{ parutionsNbr }} parutions</span>
          prises en compte dans les statistiques {{ parutionsType }}
        </div>
        <div class="container-modal-content-close" (click)="onToggleModal($event)">X</div>
      </div>
      <div class="row container-modal-content-main" id="scrollAnchor">
        <div class="parutions-list-header-filters">
          <div *ngIf="parutionsFilters?.orderByItems" class="parutions-list-header-filters-date">
            <lib-select className="filter-modal" clearable="true" placeholder="Trier par" ngClassName="filter-select"
                        [options]="parutionsFilters.orderByItems" (change)="onOrderByItems($event)">
            </lib-select>
          </div>
        </div>
        <ng-container *ngIf="parutions && parutions.length > 0 && (parutionsPages && currentPage); else noParutions">
          <div class="container-modal-content-parutions">
            <ng-container *ngFor="let parution of parutionsPages[currentPage - 1]; let i = index">
              <div class="double-parutions-container">
                <div class="mirror-overlay"
                     [ngClass]="{'mirror-parution': parution.isMirror, 'no-mirror-parution': !parution.isMirror}">
                  <lib-parution [isFromComparator]="true" [publication]="parution" [isHomologous]="true"
                                [isModal]="true"
                                [subFamily]="parution.familyLabel" [useMirror]="useMirror"
                                [isMirror]="parution.isMirror"
                                (click)="onSwitchMirror(currentPage, i, parution.isMirror)"></lib-parution>
                </div>
                <div class="mirror-overlay"
                     [ngClass]="{'mirror-parution': parution.mirror?.isMirror, 'no-mirror-parution': !parution.mirror?.isMirror}">
                  <lib-parution *ngIf="parution?.mirror" [isFromComparator]="true" [isHomologous]="true"
                                [publication]="parution?.mirror"
                                [isModal]="true" [subFamily]="parution?.mirror?.familyLabel"
                                [isMirror]="parution.mirror?.isMirror"
                                (click)="onSwitchMirror(currentPage, i, parution.mirror?.isMirror)">
                  </lib-parution>
                  <div class="container-previous" *ngIf="(parution.mirror && parution.mirror?.isMirror) || (parution && parution.isMirror)">
                    <div class="previous-year"
                         [ngClass]="{'bright-yellow': !parution.mirror?.isMirror, 'normal-yellow': parution.mirror?.isMirror}">
                      A-1
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <lib-pagination *ngIf="parutionsPages?.length > 1" [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [numberOfItems]="parutions.length"
                          (pageChange)="onPageChange($event)" [fromModal]="true"></lib-pagination>
        </ng-container>
        <ng-template #noParutions>
          <div class="no-parutions-to-compare">Pas de parutions disponibles</div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #Loading>
      <lib-loader class="loader" loaderTheme="light" loaderSize="big"></lib-loader>
    </ng-template>
  </div>
</div>

