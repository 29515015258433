import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { LoggerService } from 'src/app/services/common/logger.service';
import { MessageType, ToastService } from 'src/app/services/common/toast.service';
import { UserService } from 'src/app/services/data/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('username')
  usernameElt: any;

  returnUrl: string;
  form: FormGroup;
  isLogging = false;
  error: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private log: LoggerService,
    public toastService: ToastService
  ) {
    const params = this.route.snapshot.queryParamMap;
    this.returnUrl = params.get('returnUrl');

    // If there is a return URL and the user is already authenticated,
    // redirect to the return URL.
    if (this.returnUrl && this.userService.currentUser) {
      this.router.navigateByUrl(this.returnUrl);
    }

    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.focusOnLogin();
  }

  focusOnLogin() {
    setTimeout(() => {
      this.usernameElt.inputElement.nativeElement.focus();
      this.usernameElt.inputElement.nativeElement.select();
    }, 300);
  }

  async login() {
    // Clear previous toasts.
    this.toastService.clearToasts();

    this.isLogging = true;
    const val = this.form.value;

    setTimeout(async () => {
      if (val.userName && val.password) {
        try {
          await this.authService.login(val.userName, val.password);
          this.toastService.addToast('Authentification réussie', MessageType.SUCCESS, 2000);
          this.log.debug('User is logged in');
          this.isLogging = false;
          setTimeout(() => {
            this.router.navigateByUrl(this.returnUrl || '');
          }, 300);
        } catch (err) {
          this.isLogging = false;
          this.log.error(err);
          this.toastService.showError(err);
          this.focusOnLogin();
        }
      } else {
        this.isLogging = false;
        this.toastService.addToast('Veuillez renseigner votre identifiant et votre mot de passe', MessageType.ERROR);
        this.focusOnLogin();
      }
    }, 400);
  }

  logout() {
    this.authService.logout();
  }
}
