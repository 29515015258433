<div class="position-relative">
  <div class="lib-map-wrapper {{ mode }}" [class.evol]="isEvol" #map (click)="onMapClicked($event)"></div>
  <lib-button-gold
    [subscription]="displayCode"
    [center]="true"
    type="button"
    [goldUrl]="goldUrl"
    [accountUrl]="accountUrl"
    text="Taux de vente"
    *ngIf="displayCode > 1"
  >

  </lib-button-gold>
  <ng-container *ngIf="labelMap">* Données tous segments</ng-container>
</div>
