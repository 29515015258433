<div
  class="arrow-item"
  [ngClass]="[position, color]"
  [class.without]="withoutArrow"
  [class.is-active]="isActive"
  (click)="onArrow(arrowData)"
  *ngIf="arrowData"
>
  <div class="arrow-queue"></div>
  <div class="arrow-content">
    <span class="indicator-number">{{ arrowData.quantity }}</span>
    <span class="indicator-label">{{ arrowData.label }}</span>
  </div>
  <div *ngIf="isActive" class="arrow-desactivate">x</div>
  <div class="arrow-head"></div>
</div>
