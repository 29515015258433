<div class="ranking-container">
  <div class="ranking-tabs" [ngSwitch]="type">
    <lib-nav-switch
      *ngSwitchCase="'resellers'"
      [tabs]="[
        { label: 'Top 100 vendeurs*', value: 'top', isActive: true, iconArrow: 'up' },
        { label: 'Top 100 vendeurs', value: 'flop', isActive: false, iconArrow: 'down' }
      ]"
      background="slategray"
      (tabSelected)="onSelectTab($event)"
    ></lib-nav-switch>
    <lib-nav-switch
      *ngSwitchCase="'publications'"
      [tabs]="[
        { label: 'Top parutions', value: 'top', isActive: true },
        { label: 'Flop parutions', value: 'flop', isActive: false }
      ]"
      background="slategray"
      (tabSelected)="onSelectTab($event)"
    ></lib-nav-switch>
    <lib-nav-switch
      *ngSwitchCase="'titles'"
      [tabs]="[
        { label: 'Top titres', value: 'top', isActive: true },
        { label: 'Flop titres', value: 'flop', isActive: false }
      ]"
      background="slategray"
      (tabSelected)="onSelectTab($event)"
    ></lib-nav-switch>
  </div>
  <div *ngFor="let item of data; let i = index; let first = first" class="relative">
    <lib-button-gold
      [subscription]="displayCode?.rankings"
      [text]="type === 'resellers' ? 'Top vendeurs' : type === 'publications' ? 'Top parutions' : 'Top titres'"
      [center]="true"
      type="button"
      *ngIf="displayCode?.rankings > 1 && i > 4 && (i < 8 || displayMore)"
    ></lib-button-gold>
    <lib-ranking-item
      *ngIf="i < 8 || displayMore"
      [blur]="displayCode?.rankings > 1 && i > 4 && (i < 8 || displayMore)"
      [type]="type"
      [index]="i + 1"
      [item]="item"
      [expanded]="first"
      [compareMode]="compareMode"
      [ngClass]="{ 'ranking-item': true, 'ranking-item-first': first }"
    ></lib-ranking-item>
  </div>
  <div *ngIf="type === 'resellers'" class="ranking-asterisk-label">*Basé sur les 100 plus gros vendeurs.</div>
</div>
