<div [ngClass]="{'blur': blur, 'centered': center}">
  <button
    id="test"
    [type]="type"
    class="button"
    [ngClass]="{'button-gold': subscription > 1, 'button-silver': subscription === 2, 'column': column}"
    (click)="handleButtonClicked($event)"
    [ngbPopover]="popContent"
    autoClose="outside"
    container="body"
    placement="top"
  >
    <span>Abo</span>
    {{ text }}
  </button>
  <ng-template #popContent>
    <h3><span>Abonnement</span><span class="badge-gold" [ngClass]="{'silver': subscription === 2}">{{ subscription === 2 ? 'Silver' : 'Gold' }}</span></h3>
    <ng-container *ngIf="isLoading">
      <lib-loader displayMode="icon" loaderSize="small"></lib-loader>
    </ng-container>
    <p *ngIf="!isLoading">{{ message }}</p>
    <button *ngIf="!isLoading" (click)="displayPhone($event)">{{ showPhone ? phone : 'Contacter mon chargé de compte' }}</button>
  </ng-template>
</div>
