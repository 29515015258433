<div class="menu-container">
  <div
    *ngFor="let link of menu"
    [ngClass]="{ 'menu-item': true, link: true, active: isCurrentPage(link.url) }"
    (click)="handleChangePage(link.url)"
  >
    {{ link.label }}
    <!-- <div class="divider" [ngClass]="{ active: isCurrentPage(link.url) }"></div> -->
  </div>
  <div class="menu-item deconnection-tab" (click)="handleLogout($event)">
    <img src="/assets/icons/logout.svg" />Déconnexion
  </div>
</div>
