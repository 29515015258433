import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { GlobalErrorHandler } from './error-handler';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TemplateMasterComponent } from './pages/template-master/template-master.component';
import { TemplateComponent } from './pages/template-master/template/template.component';
import { SizeTransformPipe } from './pipes/size-transform.pipe';
import { AuthenticationGuard } from './services/common/authentication.guard';
import { SharedModule } from './shared/shared.module';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent,
    TemplateMasterComponent,
    LoginComponent,
    PageNotFoundComponent,
    SizeTransformPipe,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppConfig,
    AuthenticationGuard,
    GlobalErrorHandler,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function configFactory(config: AppConfig) {
  return () => config.load();
}
