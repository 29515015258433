<ng-container *ngIf="group" [formGroup]="group" (change)="onChange($event)">
  <ng-select
    [items]="options"
    [ngClass]="className"
    [placeholder]="placeholder"
    [searchable]="searchable"
    [notFoundText]="notFoundText"
    [searchFn]="searchFn"
    [clearable]="clearable"
    clearAllText="Réinitialiser"
    [disabled]="disabled"
    [formControlName]="controlName"
    (change)="onChange($event)"
  >
    <ng-template ng-label-tmp let-item="item">
      <span>{{ item.label }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <span [ngOptionHighlight]="search">{{ item.label }}</span>
      <!-- <lib-icon class="icon" icon="check"></lib-icon> -->
      <span class="icon" [innerHTML]="checkIcon"></span>
    </ng-template>
  </ng-select>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <span class="ng-option disabled">Aucun résultat pour "{{ searchTerm }}"</span>
  </ng-template>
</ng-container>

<ng-container *ngIf="!group">
  <ng-select
    [items]="options"
    [placeholder]="placeholder"
    [ngClass]="className"
    [notFoundText]="notFoundText"
    [searchable]="searchable"
    [searchFn]="searchFn"
    [clearable]="clearable"
    clearAllText="Réinitialiser"
    [disabled]="disabled"
    [(ngModel)]="model"
    [bindValue]="binding ? 'value' : ''"
    (ngModelChange)="onModelChange($event)"
    (change)="onChange($event)"
  >
    <ng-template ng-label-tmp let-item="item">
      <span [ngClass]="className">{{ item.label }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <span [ngOptionHighlight]="search">{{ item.label }}</span>
      <!-- <lib-icon class="icon" icon="check"></lib-icon> -->
      <span class="icon" [innerHTML]="checkIcon"></span>
    </ng-template>
    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <span class="ng-option disabled">Aucun résultat pour "{{ searchTerm }}"</span>
    </ng-template>
  </ng-select>
</ng-container>
