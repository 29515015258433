import { Injectable } from '@angular/core';
import * as log from 'loglevel';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  // Reuse loglevel functions
  setLevel = log.setLevel;
  trace = log.trace;
  debug = log.debug;
  info = log.info;
  warn = log.warn;

  error(error: string | Error | ErrorEvent, opts?: {}) {
    log.error(error, opts);
  }
}
