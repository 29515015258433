<div class="container">
  <ng-container>
    <lib-loader [loaderText]="loadingMessage" *ngIf="isLoading; else isLoaded"></lib-loader>
  </ng-container>
  <ng-template #isLoaded>
    <ng-container *ngIf="!showCompareModal; else showModal">
      <div class="asterisk">Données basées sur les parutions dont le taux de retour est > à 95%</div>
      <div class="container-title-filters">
        <div class="select-list">
          <div class="title-selector-container">
          <span class="title-selector-label" *ngIf="!brandId">{{
            'Sélectionnez une marque avant de choisir un titre'
            }}</span>
            <lib-select
              class="select title-selector"
              [options]="filters?.title_filter"
              placeholder="Selectionner un titre"
              ngClassName="filter-select"
              [disabled]="!brandId"
              (change)="handleTitleChange($event)"
              (modelChange)="handleTitleModelChange($event)"
              clearable="true"
              [model]="titleSelector"
            ></lib-select>
          </div>
          <lib-select
            class="select sliding-year-selector"
            [options]="filters?.date_filter"
            placeholder="Année glissante"
            ngClassName="filter-select"
            (modelChange)="handleSlidingYearsChange($event)"
            [model]="periodSelector"
          ></lib-select>
          <lib-select
            class="select market-selector"
            [options]="filters?.market_filter"
            placeholder="VS Sous-famille"
            ngClassName="filter-select"
            [disabled]="!titleSelected"
            (change)="handleMarketChange($event)"
            (modelChange)="handleMarketModelChange($event)"
            [model]="marketSelector"
          ></lib-select>
        </div>
        <h2 class="title">{{ filters?.component_label }}</h2>
      </div>
    </ng-container>
    <ng-template #showModal>
      <!--      TODO MODAL COMPARE     -->
      <lib-compare-parutions-list *ngIf="!refParution" [comparablePublications]="parutionsList" [currentTitle]="titleSelector?.label" [isLoading]="modalLoading" (closeModal)="handleModal()" (toCompareParution)="selectedParution($event)"></lib-compare-parutions-list>
      <lib-compare-parutions-list *ngIf="refParution" [comparablePublications]="parutionsCompareList" [isLoading]="modalLoading" [currentParution]="refParution" (closeModal)="handleModal()" (toCompareParution)="comparedParution($event)"></lib-compare-parutions-list>
    </ng-template>
  </ng-template>
</div>
