<div class="container">
  <lib-loader *ngIf="isLoading; else isLoaded" loaderSize="big"></lib-loader>
  <ng-template #isLoaded>

    <div #scrollAnchor *ngIf="!isLoading" class="container-frontpages">
      <div class="container-frontpages-header-title">
        <span class="title-frontpages-number">{{ frontpages?.frontpages?.length }}</span
        >unes
      </div>
      <div class="container-header-input">
        <div class="selector-title">
          Sélectionner la période :
        </div>
        <input
          (focus)="!datepicker.isOpen() ? datepicker.open() : ''"
          class="form-control"
          name="dp" [displayMonths]="1" [navigation]="'arrows'" [outsideDays]="'visible'"
          (dateSelect)="onSelectDate($event)"
          [showWeekNumbers]="false" ngbDatepicker #datepicker="ngbDatepicker" [readOnly]="true"
          [maxDate]="maxDate"
          [autoClose]="'outside'"
          [ngClass]="{'active': fromDate && toDate }"
          [dayTemplate]="datepickerTemplate" [value]="valueMonth"/>
        <ng-template #datepickerTemplate let-date="date"
                     let-disabled="disabled">
          <div class="custom-day"
               [class.text-muted]="disabled"
               [class.faded]="isSelected(date) || isHovered(date) && !disabled"
               [class.between]="isInside(date) || isInsideHovered(date) && !disabled"
               (mouseenter)="hoveredDate = date"
               (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </div>
        </ng-template>
        <ng-container *ngIf="fromDate && toDate && !fromDate.equals(toDate)">
          <span class="separator">et</span>
          <lib-select
            clearable="true"
            [options]="dayOptions"
            [group]="formGroup"
            controlName="day"
            placeholder="Tous les jours"
            (change)="onDayChange($event)"
            class="day-selector"
          >
          </lib-select>
        </ng-container>
      </div>
      <div class="container-frontpages-header">
        <div class="container-frontpages-checkbox">
          <label class="checkbox-button">
            <input type="checkbox" class="checkbox-button-input" id="import" name="import"
                   (change)="includeTitle($event)" [checked]="includeTitleImport">
            <span class="checkbox-button-control"></span>
            <span class="checkbox-button-label">Inclure titres imports</span>
          </label>
        </div>
        <div class="filter-container">
          <lib-select
            clearable="true"
            [options]="frontpages?.filters?.filterByItems"
            placeholder="Filter par:"
            ngClassName="filter-select"
            (change)="onFilterByItems($event)"
          ></lib-select>
        </div>
      </div>
      <div class="container-frontpages-list" *ngIf="paginationPagesItems && paginationPagesItems.length > 0">
        <ng-container *ngFor="let frontpage of paginationPagesItems[currentPage - 1]">
          <lib-parution
            [publication]="frontpage"
            class="container-frontpages-frontpage"
            [isFrontpage]="true"
          ></lib-parution>
        </ng-container>
      </div>
      <lib-pagination
        [numberOfItems]="frontpages?.frontpages?.length"
        [itemsPerPage]="itemsPerPage"
        [currentPage]="currentPage"
        (pageChange)="paginationPageChange($event)"
        *ngIf="paginationPagesItems && paginationPagesItems.length > 1"
      ></lib-pagination>
    </div>
  </ng-template>
</div>
