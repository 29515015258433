<ng-container [formGroup]="group">
  <input
    #input
    class="input"
    [formControlName]="controlName"
    [type]="type"
    [name]="inputName"
    [placeholder]="placeholderName"
    (focus)="focused = true"
    (blur)="focused = false"
    [class.has-focus]="focused"
    [attr.disabled]="disabled ? 'disabled' : null"
  />

  <img
    *ngIf="displayPassword"
    src="/assets/login-page-icons/ic-chevron-right.svg"
    (click)="showHidePassword()"
    class="password-input-icon"
    role="button"
    alt="Afficher / masquer le mot de passe"
  />
</ng-container>
