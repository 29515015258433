var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { LoggerService } from 'src/app/services/common/logger.service';
import { EventDataService } from 'src/app/services/data/event-data.service';
import { UserService } from '../../services/data/user.service';
import { GTMService } from '../../services/common/gtm.service';
import { InputService } from '../../services/common/input.service';
var TemplateMasterComponent = /** @class */ (function () {
    function TemplateMasterComponent(route, router, log, userService, authService, GTM, config, eventDataService, inputService) {
        this.route = route;
        this.router = router;
        this.log = log;
        this.userService = userService;
        this.authService = authService;
        this.GTM = GTM;
        this.config = config;
        this.eventDataService = eventDataService;
        this.inputService = inputService;
        this.data = {};
    }
    TemplateMasterComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, dataLayer, toPush;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.userService.getCurrentUser().toPromise()];
                    case 1:
                        _a.currentUser = _b.sent();
                        dataLayer = window['dataLayer'] || [];
                        toPush = this.GTM.createDataLayerObject(this.currentUser.userName);
                        dataLayer.push(toPush);
                        if (this.currentUser && this.currentUser.groups && this.currentUser.groups.editors && this.currentUser.groups.editors.length > 0) {
                            this.eventDataService.currentBrandEditor.editor = {
                                value: this.currentUser.groups.editors[0].groupId.toString(),
                                label: this.currentUser.groups.editors[0].name,
                            };
                        }
                        if (!this.paramMapSubscription) {
                            this.paramMapSubscription = this.route.paramMap.subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    this.template = params.get('template');
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateMasterComponent.prototype.ngOnDestroy = function () {
        this.paramMapSubscription.unsubscribe();
    };
    TemplateMasterComponent.prototype.onBrandChanged = function (args) {
        if (this.eventDataService.hasCompareParution()) {
            this.eventDataService.statisticsFilterDate = null;
        }
        this.eventDataService.currentBrandEditor = {
            brand: args && args.brand ? { value: args.brand.value, label: args.brand.label } : {
                value: null,
                label: null
            },
            editor: args && args.editor ? { value: args.editor.value, label: args.editor.label } : {
                value: null,
                label: null
            }
        };
        // this.eventDataService.statisticsFilterTitle = null;
    };
    TemplateMasterComponent.prototype.onEditorChanged = function (args) {
        if (this.eventDataService.hasCompareParution()) {
            this.eventDataService.statisticsFilterDate = null;
        }
        this.eventDataService.currentBrandEditor = {
            brand: {
                value: null,
                label: null
            },
            editor: args && args.editor ? { value: args.editor.value, label: args.editor.label } : {
                value: null,
                label: null
            }
        };
    };
    TemplateMasterComponent.prototype.onLogoutClicked = function () {
        this.authService.logout();
        this.router.navigate(['/login']);
        // FIXME : Should NOT do that, I know :(
        window.location.replace('/login');
    };
    TemplateMasterComponent.prototype.onPageChanged = function (evt) {
        this.log.debug('onPageChanged', evt);
        this.router.navigate(["/page/" + evt]);
        this.loadTemplate(evt);
    };
    TemplateMasterComponent.prototype.loadTemplate = function (template) {
        var _this = this;
        // const template = this.template;
        this.template = null;
        setTimeout(function () {
            _this.template = template; // template;
        });
    };
    /**
     * Called when the user changes the current page in order whether to disable the header select
     */
    TemplateMasterComponent.prototype.displayHeaderSelect = function (event) {
        this.canSwitchBrand = event;
    };
    // New part to group all parameters and avoid multiple API calls in children components (due to setter)
    TemplateMasterComponent.prototype.onBrandEditorChanged = function (event) {
        this.inputService.updateBrandEditor(event);
    };
    return TemplateMasterComponent;
}());
export { TemplateMasterComponent };
