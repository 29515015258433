import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  getErrorMessage(error: any): string {
    let errorMessage;
    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error.rejection) {
      errorMessage = `Erreur ${error.rejection.status} : ${this.getErrorMessage(error.rejection)}`;
    } else if (error.error) {
      if (error.error.error && error.error.error.message) {
        errorMessage = '' + error.error.error.message;
      } else if (error.error.message) {
        errorMessage = '' + error.error.message;
      }
    }
    if (!errorMessage) {
      errorMessage = error.message;
    }
    return errorMessage;
  }
}
