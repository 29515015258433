<div class="container" [ngClass]="{ 'without-data': !currentStats }">
  <ul class="tabs-label-list">
    <li
      class="tab-label-item"
      [ngClass]="{ active: currentTab === tabItemsEnum.ALL }"
      (click)="handleTabChange(tabItemsEnum.ALL)"
    >
      {{ 'France entière' }}
    </li>
    <li
      class="tab-label-item"
      [ngClass]="{ active: currentTab === tabItemsEnum.WAREHOUSE }"
      (click)="handleTabChange(tabItemsEnum.WAREHOUSE)"
    >
      {{ 'Détails par dépôt' }}
    </li>
  </ul>
  <section class="tabs-content">
    <div class="filter-container">
      <lib-select
        [options]="filters[currentTab].filterOptions"
        [placeholder]="filters[currentTab].filterPlaceholder"
        [(model)]="filters[currentTab].selectedFilterItem"
        (change)="handleFilterChange($event)"
        [disabled]="displayCode > 1"
        class="filter"
      ></lib-select>
      <div class="select-tips">
        <span class="tips-text">
          {{ filters[currentTab].tipsText }}
        </span>
      </div>
    </div>
    <div class="main-content" *ngIf="currentStats">
      <ng-container *ngIf="isSegmentLoading && currentTab === tabItemsEnum.ALL; else table">
        <lib-loader [loaderText]="loadingMessage" loaderSize="medium"></lib-loader>
      </ng-container>

      <ng-template #table>
        <div class="flex-table">
          <div class="flex-row row-header first">Données cumulées à J{{ JNbr || mevDelay }}</div>
          <div class="flex-row row-header">Num. en vente</div>
          <div
            class="flex-row row-header">{{ comparedParution?.previous ? 'Num. préc.' : comparedParution?.homologous ? 'Num. homo.' : 'Num. comp.' }}</div>
        </div>

        <div class="flex-table">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            text="Part de marché"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            Part de marché*
            <span class="market">{{ market === 'family' ? 'sous famille' : 'Marché SPRINT' }}</span>
          </div>
          <div class="flex-row number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats[market]?.currentPublication?.value | percent: '.0-1':'fr-FR' }}
          </div>
          <div class="flex-row previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
                  <span>
                    {{ currentStats[market] && (currentStats[market][numberToCompare]?.value | percent: '.0-1':'fr-fr') }}
                  </span>
              <span class="points"
              >{{ currentStats[market] && (currentStats[market][numberToCompare]?.mirror | addSign) }}pts</span
              >
            </div>
          </div>
        </div>

        <div class="flex-table">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            text="Taux de vente"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            Taux de vente*
          </div>
          <div class="flex-row number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.soldRate?.currentPublication?.value | percent: '.0-1':'fr-fr' }}
          </div>
          <div class="flex-row previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.soldRate)[numberToCompare]?.value | percent: '.0-1':'fr-fr' }}</span>
              <span class="points">{{ (currentStats?.soldRate)[numberToCompare]?.mirror | addSign }}pts</span>
            </div>
          </div>
        </div>

        <div class="flex-table">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            text="Taux de rupture"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            Taux de rupture*
          </div>
          <div class="flex-row number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.soldOutRate?.currentPublication?.value | percent: '.0-1':'fr-fr' }}
          </div>
          <div class="flex-row previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.soldOutRate)[numberToCompare]?.value | percent: '.0-1':'fr-fr' }}</span>
              <span class="points">{{ (currentStats?.soldOutRate)[numberToCompare]?.mirror | addSign }}pts</span>
            </div>
          </div>
        </div>

        <div class="flex-table" *ngIf="displayAll">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            text="Ex. fournis"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row flex-row-footer cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            Ex. fournis
          </div>
          <div class="flex-row flex-row-footer number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.deliveredItems?.currentPublication?.value | number: '':'fr-FR' }}
          </div>
          <div class="flex-row flex-row-footer previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.deliveredItems)[numberToCompare]?.value | number: '':'fr-FR' }}</span>
              <span class="points">{{
                (currentStats?.deliveredItems)[numberToCompare]?.mirror | custompercent}}</span>
            </div>
          </div>
        </div>

        <div class="flex-table" *ngIf="displayAll">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            text="Ex. vendus"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row flex-row-footer cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            Ex. vendus*
          </div>
          <div class="flex-row flex-row-footer number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.soldItems?.currentPublication?.value | number: '':'fr-FR' }}
          </div>
          <div class="flex-row flex-row-footer previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.soldItems)[numberToCompare]?.value | number: '':'fr-FR' }}</span>
              <span class="points">{{ (currentStats?.soldItems)[numberToCompare]?.mirror | custompercent }}</span>
            </div>
          </div>
        </div>

        <div class="flex-table" *ngIf="displayAll">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            [text]="market === 'family' ? 'Ex. vendus ss famille*' : 'Ex. vendus marché*'"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row flex-row-footer cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            {{ market === 'family' ? 'Ex. vendus ss famille*' : 'Ex. vendus marché*' }}
          </div>
          <div class="flex-row flex-row-footer number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats[marketSoldItems].currentPublication?.value | number: '':'fr-FR' }}
          </div>
          <div class="flex-row flex-row-footer previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ currentStats[marketSoldItems][numberToCompare]?.value | number: '':'fr-FR' }}</span>
              <span class="points">{{
                currentStats[marketSoldItems][numberToCompare]?.mirror | custompercent
                }}</span>
            </div>
          </div>
        </div>

        <div class="flex-table" *ngIf="displayAll">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            text="PDV servis"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row flex-row-footer cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="pdv">
              <span>PDV servis</span>
            </div>
          </div>
          <div class="flex-row flex-row-footer number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.deliveredResselers?.currentPublication?.value | number: '':'fr-FR' }}
          </div>
          <div class="flex-row flex-row-footer previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.deliveredResselers)[numberToCompare]?.value | number: '':'fr-FR' }}</span>
              <span class="points">{{
                (currentStats?.deliveredResselers)[numberToCompare]?.mirror | custompercent
                }}</span>
            </div>
          </div>
        </div>

        <div class="flex-table" *ngIf="displayAll">
          <lib-button-gold
            [subscription]="displayCode"
            *ngIf="displayCode > 1"
            [text]="currentParutionJText"
            [goldUrl]="goldUrl"
            [accountUrl]="accountUrl"
            [center]="true"
            type="button">
          </lib-button-gold>
          <div class="flex-row flex-row-footer cumulative-data first" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="pdv">
              <span>{{currentParutionJText}}</span>
            </div>
          </div>
          <div class="flex-row flex-row-footer number-for-sale" [ngClass]="{'text-blur': displayCode > 1}">
            {{ currentStats?.sellersCount?.currentPublication?.value | number: '':'fr-FR' }}
          </div>
          <div class="flex-row flex-row-footer previous-number" [ngClass]="{'text-blur': displayCode > 1}">
            <div class="previous-number-container">
              <span>{{ (currentStats?.sellersCount)[numberToCompare]?.value | number: '':'fr-FR' }}</span>
              <span class="points">{{
                (currentStats?.sellersCount)[numberToCompare]?.mirror | custompercent
                }}</span>
            </div>
          </div>
        </div>

        <button type="button" class="button display-more-button" (click)="displayMore()">
          {{ displayAll ? 'Moins de détails' : 'Plus de détails' }}
          <span class="arrow" [ngClass]="[displayAll ? 'up' : 'down']"></span>
        </button>
      </ng-template>
    </div>
  </section>
</div>
