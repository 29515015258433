<div class="modal-container">
  <div class="modal-content-container">
    <div class="close-button-container" (click)="onCloseModal()">X</div>
    <ng-container *ngIf="!isLoading; else loader">
      <div class="modal-content-header-container">
        <ng-container *ngIf="!specifiedOperation">
          <span class="title">Actions mises en place</span>
          <span>{{opeNbr}} actions mises en place sur {{parutionsNbr}} parutions</span>
        </ng-container>
        <ng-container *ngIf="specifiedOperation">
          <span class="title">{{specifiedOperation}}</span>
          <span>{{opeNbr}} {{specifiedOperation | lowercase}} sur {{parutionsNbr}} parutions</span>
        </ng-container>
      </div>
      <div *ngIf="publicationBusiness?.length > 0" class="modal-filter-container">
        <lib-select class="filter-orders" [options]="orders" placeholder="Trier par" (change)="onOrderByItems($event)">
        </lib-select>
      </div>
      <div class="modal-content-main-container">
        <ng-container *ngIf="publicationBusiness?.length > 0; else noPublications">
          <div class="parution-business-container" *ngFor="let parution of publicationBusiness">
            <div class="parution-business-infos">
              <div class="title">
                {{ parution?.publicationId | RemoveAfterHyphenPipe }} • {{ parution?.name }}
                n°{{ parution?.publicationNumber }}
                <ng-container *ngIf="parution?.price"> • {{ parution?.price | number: '.2':'fr-FR' }} €</ng-container>
              </div>
              <div class="date" *ngIf="parution?.sellingStartDate && parution?.sellingEndDate">MEV du {{ parution?.sellingStartDate | date: 'dd/MM/yyyy' }} au
                {{ parution?.sellingEndDate | date: 'dd/MM/yyyy' }}</div>
            </div>
            <div class="parution-business-main">
              <div class="parution-business-cover"
                   [ngStyle]="{ 'background-image': 'url(' + parution?.coverUrl + ')' }">
              </div>
              <div class="parution-business-operations">
                <ng-container *ngFor="let bo of parution.businessOperations">
                  <div class="bo-container" *ngIf="showBusinessOperations(bo)">
                    <div class="bo-type" *ngIf="!specifiedOperation">
                      {{bo.type | businessOperationsType}}
                    </div>
                    <div *ngIf="hasValidType(bo)" class="bo-infos">
                      {{bo.name}}
                    </div>
                    <div *ngIf="showDate(bo)" class="bo-infos">
                      du {{ bo?.start | date: 'dd/MM/yyyy' }} au
                      {{ bo?.end | date: 'dd/MM/yyyy' }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noPublications>
          <span class="no-parution-text">Aucune parution n'est disponible.</span>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <ng-template #loader>
    <lib-loader class="loader" loaderSize="big" loaderTheme="light"></lib-loader>
  </ng-template>
</div>
