import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  htmlTemplate: string;

  constructor(private http: HttpClient, private config: AppConfig, private router: Router) {
  }

  async ngOnInit() {
    const res = await this.http.get<any>(`${this.config.apiUrl}/maintenance`).toPromise();
    if (res.status === true) {
      this.htmlTemplate = res.message;
    } else {
      this.router.navigate(['']);
    }
  }

}
