<div class="menu-container">
  <div
    *ngFor="let link of menu"
    class="link"
    [ngClass]="{ active: isCurrentPage(link.url) }"
    (click)="handleChangePage(link.url)"
  >
    <div>
      {{ link.label }}
    </div>
  </div>
</div>
