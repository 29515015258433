var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { LoggerService } from 'src/app/services/common/logger.service';
import { EventDataService } from 'src/app/services/data/event-data.service';
import { InputService } from '../../../services/common/input.service';
var TemplateComponent = /** @class */ (function () {
    function TemplateComponent(httpClient, route, router, log, config, eventDataService, inputService) {
        this.httpClient = httpClient;
        this.route = route;
        this.router = router;
        this.log = log;
        this.config = config;
        this.eventDataService = eventDataService;
        this.inputService = inputService;
        this.canSwitchBrand = new EventEmitter();
        this.data = {};
        this.selectedTabs = {};
    }
    Object.defineProperty(TemplateComponent.prototype, "template", {
        get: function () {
            return this._template;
        },
        set: function (value) {
            this._template = value;
            if (value) {
                this.loadTemplate();
            }
        },
        enumerable: true,
        configurable: true
    });
    TemplateComponent.prototype.loadTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParams, queryParamsKeys, key, i, layout;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.template) {
                            return [2 /*return*/];
                        }
                        // Reinit some store service data
                        this.eventDataService.statisticsFilterTitle = undefined;
                        this.eventDataService.statisticsFilterDate = undefined;
                        this.eventDataService.statisticsFilterMarket = undefined;
                        this.eventDataService.realtimeSegment = undefined;
                        this.eventDataService.realtimeCurrentWarehouse = undefined;
                        this.eventDataService.realtimeCurrentMarketType = undefined;
                        this.eventDataService.realtimeCurrentComparisonValue = undefined;
                        this.eventDataService.incidentsIndicatorFilter = undefined;
                        this.eventDataService.parutionListFilter = undefined;
                        this.eventDataService.upComingIndicatorFilter = undefined;
                        this.log.debug('TEMPLATE', this.template);
                        queryParams = this.route.snapshot.queryParams;
                        this.log.debug('QUERY PARAMS', queryParams);
                        this.selectedTabs = [];
                        queryParamsKeys = Object.keys(queryParams);
                        this.queryParams = [];
                        for (i = 0; i < queryParamsKeys.length; i++) {
                            key = queryParamsKeys[i];
                            this.queryParams[key] = queryParams[key];
                            if (key.indexOf('tab-') === 0) {
                                this.selectedTabs[key.substr(4)] = queryParams[key];
                            }
                        }
                        return [4 /*yield*/, this.httpClient.get(this.config.apiUrl + "/layout?page=" + this.template).toPromise()];
                    case 1:
                        layout = _a.sent();
                        this.templateTree = layout.rows;
                        this.templateTree.forEach(function (row) {
                            row.components.forEach(function (comp) {
                                comp.apiUrl = _this.config.apiUrl + comp.apiUrl;
                            });
                        });
                        this.canSwitchBrand.emit(layout.canSwitchBrand);
                        this.log.debug('TEMPLATE TREE :: ', this.templateTree);
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateComponent.prototype.onTabChanged = function (evt, componentId) {
        this.log.debug('onTabChanged', evt);
        var newTemplate = evt.value.substr(evt.value.indexOf('=') + 1);
        var qParams = {};
        qParams['tab-' + componentId] = newTemplate;
        this.router.navigate(["/page/tdb"], {
            replaceUrl: true,
            queryParams: qParams,
            queryParamsHandling: 'merge',
        });
        // this.loadTemplate();
        this.selectedTabs['comp-' + componentId] = newTemplate;
        this.queryParams['tab-' + componentId] = newTemplate;
    };
    TemplateComponent.prototype.onFilterStatsTitleChange = function (event) {
        // ORDER IS IMPORTANT !
        this.eventDataService.statisticsFilterTitle = event;
        this.inputService.updateTitle(event);
    };
    TemplateComponent.prototype.onFilterStatsDateChange = function (event) {
        // ORDER IS IMPORTANT !
        this.eventDataService.statisticsFilterDate = event;
        this.inputService.updatePeriod(event);
    };
    TemplateComponent.prototype.onFilterStatsMarketChange = function (event) {
        this.eventDataService.statisticsFilterMarket = event;
    };
    TemplateComponent.prototype.onRealTimeSegmentChange = function (segment) {
        this.eventDataService.realtimeSegment = segment;
    };
    TemplateComponent.prototype.onRealTimeCurrentWarehouseChange = function (warehouse) {
        this.eventDataService.realtimeCurrentWarehouse = warehouse;
    };
    TemplateComponent.prototype.onRealTimeCurrentMarketTypeChange = function (marketType) {
        this.eventDataService.realtimeCurrentMarketType = marketType;
    };
    TemplateComponent.prototype.onIncidentsIndicatorClicked = function (event) {
        this.eventDataService.incidentsIndicatorFilter = event;
    };
    TemplateComponent.prototype.onParutionFilterChange = function (event) {
        this.eventDataService.parutionListFilter = event;
    };
    TemplateComponent.prototype.onIncludeHS = function (event) {
        this.eventDataService.includeHS = event;
    };
    TemplateComponent.prototype.onUpComingFilterChange = function (event) {
        this.eventDataService.upComingIndicatorFilter = event;
    };
    TemplateComponent.prototype.onCompareParution = function (event) {
        // ORDER IS IMPORTANT !
        this.eventDataService.compareParution = event;
        this.inputService.updatePeriod(event.refParution && event.refParution.periodId);
    };
    TemplateComponent.prototype.onComparedParution = function (event) {
        this.eventDataService.comparedParution = event;
    };
    return TemplateComponent;
}());
export { TemplateComponent };
