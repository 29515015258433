<div class="container" (click)="stopClickEventParution($event)">
  <div class="container-modal-button" (click)="onToggleModal($event)">
    <div class="container-modal-button-icon">
      <img src="/assets/icons/shape.svg" />
    </div>
  </div>
  <div class="container-modal-overlay" *ngIf="modalToggled"></div>
  <div class="container-modal-content" *ngIf="modalToggled && comparedParutions">
    <div class="container-modal-content-header">
      <div class="container-modal-content-title">Comparaison à {{ mevDelayDelta }}</div>
      <div class="container-modal-content-close" (click)="onToggleModal($event)">X</div>
    </div>
    <div class="container-modal-content-main">
      <div class="container-modal-content-selector">
        <div class="container-modal-content-selector-title">Comparer avec</div>
        <div class="container-modal-content-selector-navigator">
          <lib-nav-tabs [tabs]="tabsList" (tabSelected)="onSelectTab($event)"></lib-nav-tabs>
        </div>
      </div>
      <ng-container *ngIf="comparedParutions.previous; else noParutionsToCompare">
        <div class="container-modal-content-parutions" *ngIf="comparedValue === 'previous'; else homologous">
          <lib-parution
            class="container-modal-content-now"
            [publication]="comparedParutions.current"
            [mevDelayDeltaComparator]="mevDelayDelta"
            [leftStatus]="{ value: comparedParutions.current.nbSold, label: 'Ex. vendus', alertColor: 'white' }"
            [rightStatus]="{
              value: comparedParutions.current.ruptureRate,
              label: 'Tx rupture',
              isPercent: true,
              alertColor: 'orange'
            }"
            [isFromComparator]="true"
          ></lib-parution>
          <lib-parution
            class="container-modal-content-previous"
            [publication]="comparedParutions.previous"
            [mevDelayDeltaComparator]="mevDelayDelta"
            [leftStatus]="{ value: comparedParutions?.previous?.nbSold, label: 'Ex. vendus', alertColor: 'white' }"
            [rightStatus]="{
              value: comparedParutions?.previous?.ruptureRate,
              label: 'Tx rupture',
              isPercent: true,
              alertColor: 'white'
            }"
            [isFromComparator]="true"
          ></lib-parution>
        </div>
        <ng-template class="container-modal-content-parutions" #homologous>
          <ng-container *ngIf="comparedParutions.homologous">
            <div class="container-modal-content-parutions">
              <lib-parution
                class="container-modal-content-now"
                [publication]="comparedParutions.current"
                [mevDelayDeltaComparator]="mevDelayDelta"
                [isHomologous]="true"
                [leftStatus]="{ value: comparedParutions.current.nbSold, label: 'Ex. vendus', alertColor: 'white' }"
                [rightStatus]="{
                  value: comparedParutions.current.ruptureRate,
                  label: 'Tx rupture',
                  isPercent: true,
                  alertColor: 'orange'
                }"
                [isFromComparator]="true"
              ></lib-parution>
              <lib-parution
                class="container-modal-content-previous"
                [publication]="comparedParutions.homologous"
                [mevDelayDeltaComparator]="mevDelayDelta"
                [isHomologous]="true"
                [leftStatus]="{
                  value: comparedParutions?.homologous?.nbSold,
                  label: 'Ex. vendus',
                  alertColor: 'white'
                }"
                [rightStatus]="{
                  value: comparedParutions?.homologous?.ruptureRate,
                  label: 'Tx rupture',
                  isPercent: true,
                  alertColor: 'white'
                }"
                [isFromComparator]="true"
              ></lib-parution>
            </div>
          </ng-container>
          <ng-container *ngIf="!comparedParutions.homologous">
            <div class="container-modal-content-parutions">
              <lib-parution
                class="container-modal-content-now"
                [publication]="comparedParutions.current"
                [mevDelayDeltaComparator]="mevDelayDelta"
                [leftStatus]="{ value: comparedParutions.current.nbSold, label: 'Ex. vendus', alertColor: 'white' }"
                [rightStatus]="{
                  value: comparedParutions.current.ruptureRate,
                  label: 'Tx rupture',
                  isPercent: true,
                  alertColor: 'orange'
                }"
                [isFromComparator]="true"
              ></lib-parution>
              <div class="container-modal-content-previous no-homologous">
                Pas de parution à cette date l'année précédente
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #noParutionsToCompare>
        <div class="no-parutions-to-compare">Pas de parutions disponibles à la comparaison pour cette parution</div>
      </ng-template>
    </div>
  </div>
</div>
