<ng-container>
  <lib-loader *ngIf="isLoading; else isLoaded" loaderSize="big" [loaderText]="loadingMessage"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div class="container">
    <div class="flex-container">
      <div class="cover-container"
           [ngStyle]="selectedParution ? {'background-image': 'url(' + selectedParution?.coverUrl + ')'} : {'background-color': '#cecece'}">
      </div>
      <div class="infos-container">
        <div class="title">
          Sélectionner une marque puis une titre par nom ou par codif
        </div>
        <div class="selector-container">
          <lib-select class="selector" searchable="true"
                      [options]="selectorOptions" (change)="onSelect($event)" [group]="group" controlName="title"
                      notFoundText="Pas de titres disponibles" [searchFn]="searchFn" placeholder="Titre"></lib-select>
          <lib-select class="selector" [group]="group" controlName="number" [options]="selectorNumber" notFoundText="Pas de publications disponibles"
                      (change)="onSelectNumber($event)" placeholder="Numéro (ex: 433)"></lib-select>
        </div>
        <div class="button-container">
          <lib-button *ngIf="selectedParution" text="Choisir la parution"
                      (click)="goToParutionDetails(selectedParution)"></lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
