import { Injectable, NgZone } from '@angular/core';
import { ErrorService } from './error.service';

export enum MessageType {
  ERROR = 'error',
  SUCCESS = 'success',
}

interface ToastItem {
  message: string;
  messageType: MessageType;
  timeoutInMs: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toasts: ToastItem[] = [];

  constructor(private zone: NgZone, private errorService: ErrorService) {}

  addToast(message: string, messageType: MessageType = MessageType.SUCCESS, timeoutInMs = 5000) {
    // Create the toast item.
    const toastItem: ToastItem = { message: message.replace('\n', '<br/>'), messageType, timeoutInMs };
    // Add the toast to the array.
    // Note: Zone is used
    this.zone.run(() => this.toasts.push(toastItem));
    setTimeout(() => {
      this.zone.run(() => {
        const toastIndex = this.toasts.indexOf(toastItem);
        if (toastIndex > -1) {
          this.toasts.splice(this.toasts.indexOf(toastItem), 1);
        }
      });
    }, timeoutInMs);
  }

  showError(error: any, message = null, timeoutInMs = 10000) {
    let toastMessage = message ? `${message}<br/>` : '';
    toastMessage += this.errorService.getErrorMessage(error);
    this.addToast(toastMessage, MessageType.ERROR, timeoutInMs);
  }

  clearToasts() {
    this.toasts.splice(0, this.toasts.length);
  }
}
