<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <div class="account-incharge-data">
    <div class="account-incharge-title">Chargé de compte</div>
    <div class="account-incharge-name">{{ accountIncharge.firstName }} {{ accountIncharge.lastName }}</div>
    <div class="account-incharge-phone">{{ accountIncharge.phoneNumber }}</div>
  </div>
</ng-template>
