import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccountInchargeModule,
  AnnualSalesModule,
  BusinessOperationsModule,
  ButtonModule,
  FinancialStatsModule,
  FooterModule,
  FrontpagesModule,
  HeaderModule,
  HelloModule,
  IncidentsIndicatorModule,
  IncidentsOverviewModule,
  InputModule,
  LifecycleUpcomingModule,
  PageTitleModule,
  ParutionsListModule,
  ParutionsIncludedModule,
  PublicationDetailsModule,
  PublicationTimelineModule,
  RankingsSetModule,
  RealtimeStatsModule,
  SalesPointsStatsModule,
  SalesSegmentModule,
  SalesStatisticPageFiltersModule,
  SalesStatsModule,
  SelectModule,
  SelectTabsModule,
  ToasterModule,
  UpcomingParutionsListModule,
  UpdateMessageModule,
  ConsolidationParutionsListModule,
  VentesScanneesModule,
} from 'components-library';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    AccountInchargeModule,
    AnnualSalesModule,
    BusinessOperationsModule,
    ButtonModule,
    CommonModule,
    FinancialStatsModule,
    FooterModule,
    FrontpagesModule,
    HeaderModule,
    HelloModule,
    IncidentsIndicatorModule,
    IncidentsOverviewModule,
    InputModule,
    LifecycleUpcomingModule,
    PageTitleModule,
    ParutionsListModule,
    ParutionsIncludedModule,
    PublicationDetailsModule,
    PublicationTimelineModule,
    RankingsSetModule,
    RealtimeStatsModule,
    SalesPointsStatsModule,
    SalesSegmentModule,
    SalesStatisticPageFiltersModule,
    SalesStatsModule,
    SelectModule,
    SelectTabsModule,
    ToasterModule,
    UpcomingParutionsListModule,
    UpdateMessageModule,
    ConsolidationParutionsListModule,
    VentesScanneesModule,
  ],
  exports: [
    AccountInchargeModule,
    AnnualSalesModule,
    BusinessOperationsModule,
    ButtonModule,
    FinancialStatsModule,
    FooterModule,
    FrontpagesModule,
    HeaderModule,
    HelloModule,
    IncidentsIndicatorModule,
    IncidentsOverviewModule,
    InputModule,
    LifecycleUpcomingModule,
    PageTitleModule,
    ParutionsListModule,
    ParutionsIncludedModule,
    PublicationDetailsModule,
    PublicationTimelineModule,
    RankingsSetModule,
    RealtimeStatsModule,
    SalesPointsStatsModule,
    SalesSegmentModule,
    SalesStatisticPageFiltersModule,
    SalesStatsModule,
    SelectModule,
    SelectTabsModule,
    ToasterModule,
    UpcomingParutionsListModule,
    UpdateMessageModule,
    ConsolidationParutionsListModule,
    VentesScanneesModule,
  ],
})
export class SharedModule {}
