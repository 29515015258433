import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  constructor() {
  }

  createDataLayerObject(userName: string): Object {
    if (userName) {
      if (userName.includes('@')) {
        userName = userName.split('@')[0];
      }
      return {
        'status': 'Connecté',
        'userId': userName.substr(0, 5),
        'editorCode': userName,
      };
    }
    return {
      'status': 'Non connecté',
      'userId': '',
      'editorCode': '',
    };
  }
}
