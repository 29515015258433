<ng-container *ngIf="isLoading; else isLoaded">
  <lib-loader [loaderText]="loadingMessage" loaderSize="big"></lib-loader>
</ng-container>

<ng-template #isLoaded>
  <ng-container>
    <div class="rankings-set-wrapper">
      <div class="rankings-set-top">
        <div class="rankings-set-title">Les tops et les flops</div>
        <lib-select
                [options]="[
        { value: 'soldNumber', label: rankingData.filters.value },
        { value: 'mirror', label: rankingData.filters.mirror }
      ]"
                placeholder="Par défaut: Quantité vendue "
                (change)="changeRankings($event)"
        ></lib-select>
      </div>
      <div class="rankings-set-down">
        <div class="rankings-set-rankings-desktop">
          <lib-ranking
                  type="resellers"
                  [displayMore]="displayAll"
                  [top]="data.resellers.top"
                  [flop]="data.resellers.flop"
                  [displayCode]="data.resellers.displayCode"
                  [compareMode]="compareParution?.showCompare"
                  class="rankings-set-ranking"
          ></lib-ranking>
          <lib-ranking
                  type="publications"
                  [displayMore]="displayAll"
                  [top]="data.publications.top"
                  [flop]="data.publications.flop"
                  [displayCode]="data.publications.displayCode"
                  [compareMode]="compareParution?.showCompare"
                  class="rankings-set-ranking margin"
          ></lib-ranking>
          <lib-ranking
                  type="titles"
                  [displayMore]="displayAll"
                  [top]="data.titles.top"
                  [flop]="data.titles.flop"
                  [displayCode]="data.titles.displayCode"
                  [compareMode]="compareParution?.showCompare"
                  class="rankings-set-ranking margin"
          ></lib-ranking>
        </div>
      </div>
      <div class="rankings-set-rankings-mobile" (window:resize)="updateSize()">
        <div class="content-container">
          <swiper class="swiper-container" [config]="config">
            <div *ngFor="let slide of [1, 2, 3]; let i = index" class="swiper-slide">
              <div>
                <lib-ranking
                        *ngIf="i === 0"
                        type="resellers"
                        [displayMore]="displayAll"
                        [top]="data.resellers.top"
                        [flop]="data.resellers.flop"
                        [displayCode]="data.resellers.displayCode"
                        [compareMode]="compareParution?.showCompare"
                        class="rankings-set-ranking"
                ></lib-ranking>
                <lib-ranking
                        *ngIf="i === 1"
                        type="publications"
                        [displayMore]="displayAll"
                        [top]="data.publications.top"
                        [flop]="data.publications.flop"
                        [displayCode]="data.publications.displayCode"
                        [compareMode]="compareParution?.showCompare"
                        class="rankings-set-ranking"
                ></lib-ranking>
                <lib-ranking
                        *ngIf="i === 2"
                        type="titles"
                        [displayMore]="displayAll"
                        [top]="data.titles.top"
                        [flop]="data.titles.flop"
                        [displayCode]="data.titles.displayCode"
                        [compareMode]="compareParution?.showCompare"
                        class="rankings-set-ranking"
                ></lib-ranking>
                <div class="rankings-set-button-background">
                  <button *ngIf="displayButton" type="button" class="rankings-set-button" (click)="displayMore()">
                    {{ displayAll ? 'Afficher moins' : 'Afficher plus' }}
                    <span [ngClass]="[displayAll ? 'arrow-up' : 'arrow-down']"></span>
                  </button>
                </div>
              </div>
            </div>
          </swiper>
        </div>
      </div>
      <div class="rankings-set-button-background desktop">
        <button *ngIf="displayButton" type="button" class="rankings-set-button" (click)="displayMore()">
          {{ displayAll ? 'Afficher moins' : 'Afficher plus' }}
          <span [ngClass]="[displayAll ? 'arrow-up' : 'arrow-down']"></span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
